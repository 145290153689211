import React, { Component } from 'react';
import styled from 'styled-components';
import MailchimpSubscribe from 'react-mailchimp-subscribe';

import { mailChimpUrl } from '../config/mailChimp';
import { colors } from '../constants/colors';

const CustomForm = ({ status, message, onValidated }) => {
	let email;
	const submit = () =>
		email &&
		email.value.indexOf('@') > -1 &&
		onValidated({
			EMAIL: email.value,
		});

	return (
		<InnerWrapper>
			{status === 'sending' && <div style={{ color: 'blue' }}>sending...</div>}
			{status === 'error' && <div style={{ color: 'red' }} dangerouslySetInnerHTML={{ __html: message }} />}
			{status === 'success' && <div style={{ color: 'green' }} dangerouslySetInnerHTML={{ __html: message }} />}
			<Form>
				<FormInput ref={(node) => (email = node)} type='email' placeholder='Your email' />
				<br />
				<FormButton onClick={submit}>Submit</FormButton>
			</Form>
		</InnerWrapper>
	);
};

class Subscribe extends Component {
	render() {
		return (
			<Container>
				<MessageHeader>Subscribe to receive daily updates:</MessageHeader>
				<MailchimpSubscribe
					url={mailChimpUrl}
					render={({ subscribe, status, message }) => (
						<CustomForm status={status} message={message} onValidated={(formData) => subscribe(formData)} />
					)}
				/>
			</Container>
		);
	}
}

const Container = styled.section`
	width: auto;
	height: 40vh;
	background-color: ${colors.whiteColor};
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;

const InnerWrapper = styled.div`
	width: 500px;
	margin: 30px auto;
`;

const MessageHeader = styled.h1`
	font-size: 38px;
	font-weight: 700;
	text-align: center;
	color: ${colors.secondaryColor};
	margin: 0 0 20px;
`;

const Form = styled.div`
	width: 80%;
	padding: 20px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	/* background-color: crimson; */
`;

const FormInput = styled.input`
	width: 100%;
	padding: 10px;
	border-radius: 5px;
	background-color: ${colors.lightGreyColor};

	&:focus {
		outline: 1px solid ${colors.primaryColor};
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
		padding: 9px;
	}
`;

const FormButton = styled.button`
	padding: 10px 15px;
	border-radius: 5px;
	margin-left: 10px;
	background-color: ${colors.primaryColor};
	color: ${colors.secondaryColor};
`;

export default Subscribe;

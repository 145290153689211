export const fan_of_the_year = [
	{
		finalist_id: 2,
		finalist_name: 'Zama K Ngcobo',
		sport: 'football',
	},
];

export const player_of_the_year = [
	{
		finalist_id: 10,
		finalist_name: 'Rhoda Mulaudzi',
		team: 'Mamelodi Sundowns Ladies',
		logo:
			'https://res.cloudinary.com/oleskoolcodes/image/upload/v1600532752/teamLogos/Mamelodi%20Sundowns/lxuunmrsdbq2m7m9gmjp.png',
		sport: 'football',
	},
];

export const team_of_the_year = [
	{
		finalist_id: 16,
		finalist_name: 'Mamelodi Sundowns',
		team: 'Mamelodi Sundowns',
		logo:
			'https://res.cloudinary.com/oleskoolcodes/image/upload/v1600532752/teamLogos/Mamelodi%20Sundowns/lxuunmrsdbq2m7m9gmjp.png',
		sport: 'football',
	},
];

export const supporters_branch_of_the_year = [
	{
		finalist_id: 23,
		finalist_name: 'Supersport United Lotus Branch',
		team: 'Supersport United',
		logo:
			'https://res.cloudinary.com/oleskoolcodes/image/upload/v1566735646/teamLogos/Supersport%20United/zicpfenuz9tvk88yckld.png',
		sport: 'football',
	},
];

export const administrator_of_the_year = [
	{
		finalist_id: 24,
		finalist_name: 'Panyaza Lesufi',
		team: 'Swallows FC',
		logo:
			'https://res.cloudinary.com/oleskoolcodes/image/upload/v1600533548/teamLogos/Swallows%20FC/jtcmsmtcjoa6byeec3vr.jpg',
		sport: 'football',
	},
];

export const young_player_of_the_year = [
	{
		finalist_id: 29,
		finalist_name: 'Tebogo Mokoena',
		team: 'Supersport United',
		logo:
			'https://res.cloudinary.com/oleskoolcodes/image/upload/v1566735646/teamLogos/Supersport%20United/zicpfenuz9tvk88yckld.png',
		sport: 'football',
	},
];

export const coach_of_the_year = [
	{
		finalist_id: 32,
		finalist_name: 'Pitso Mosimane',
		team: 'Mamelodi Sundowns',
		logo:
			'https://res.cloudinary.com/oleskoolcodes/image/upload/v1600532752/teamLogos/Mamelodi%20Sundowns/lxuunmrsdbq2m7m9gmjp.png',
		sport: 'football',
	},
];

export const national_team_of_the_year = [
	{
		finalist_id: 34,
		finalist_name: 'Springboks',
		team: 'Rugby National Team',
		logo:
			'https://res.cloudinary.com/oleskoolcodes/image/upload/v1600533774/teamLogos/National%20Teams/fnrephw4balwj4yzibdc.png',
		sport: 'rugby',
	},
];

import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { colors } from '../../constants/colors';

import fcaLogo from '../../img/fca_logo.png';

export default function VotingComp() {
	const auth = useSelector((state) => state.auth.user);

	if (!auth) return <Redirect to='/signin' />;
	return (
		<Container>
			<GeneralText>Welcome to your voting page, click on one of the two categories to vote.</GeneralText>
			<CategoriesContainer>
				<Link to='/category1'>
					<CategoriesBox>
						<FCAlogo src={fcaLogo} alt='fca_logo' />
						<GeneralText>Player of the Year</GeneralText>
					</CategoriesBox>
				</Link>
				<Link to='/category2'>
					<CategoriesBox>
						<FCAlogo src={fcaLogo} alt='fca_logo' />
						<GeneralText>Fan of the Year</GeneralText>
					</CategoriesBox>
				</Link>
				<Link to='/category3'>
					<CategoriesBox>
						<FCAlogo src={fcaLogo} alt='fca_logo' />
						<GeneralText>Rewind Award</GeneralText>
					</CategoriesBox>
				</Link>
			</CategoriesContainer>
		</Container>
	);
}

const Container = styled.div`
	min-height: 500px;
	width: 100%;
	margin: 80px 0;

	display: flex;
	flex-direction: column;
	align-items: center;
`;

const CategoriesContainer = styled.div`
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	align-items: center;
	padding: 20px 50px;
	height: 100%;

	@media screen and (max-width: 900px) {
		flex-direction: column;
	}
	@media screen and (max-width: 850px) {
		flex-direction: column;
	}
	@media screen and (max-width: 768px) {
		flex-direction: column;
	}
	@media screen and (max-width: 600px) {
		flex-direction: column;
	}
	@media screen and (max-width: 300px) {
		flex-direction: column;
	}
`;

const CategoriesBox = styled.div`
	width: 400px;
	height: 200px;
	border-radius: 15px;
	margin: 30px;
	background-color: #f6e05e;
	display: flex;
	flex-direction: column;
	align-content: center;
	justify-content: center;
`;

const GeneralText = styled.h2`
	font-size: 22px;
	font-weight: 400;
	margin-top: 10px;
	text-align: center;
	color: ${colors.secondaryColor};
`;

const FCAlogo = styled.img`
	height: auto;
	width: 100px;
	align-self: center;
`;

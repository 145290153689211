import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';

const firebaseConfig = {
	dev: {
		apiKey: 'AIzaSyC3yNcONr88A0WRerUhyr75cX5UqtmeXQc',
		authDomain: 'fca-production-eef93.firebaseapp.com',
		databaseURL: 'https://fca-production-eef93.firebaseio.com',
		projectId: 'fca-production-eef93',
		storageBucket: 'fca-production-eef93.appspot.com',
		messagingSenderId: '168976541888',
		appId: '1:168976541888:web:78814c05b7f714a9b6a525',
		measurementId: 'G-T4RT4KRRR9',
	},
	prod: {
		apiKey: 'AIzaSyClK-a_J4QE6Z6I1UeYpVv6ahZs7gmLU4k',
		authDomain: 'fca-dev-c3b3d.firebaseapp.com',
		databaseURL: 'https://fca-dev-c3b3d.firebaseio.com',
		projectId: 'fca-dev-c3b3d',
		storageBucket: 'fca-dev-c3b3d.appspot.com',
		messagingSenderId: '1033877930762',
		appId: '1:1033877930762:web:9544483be634c04c7cf0f3',
	},
};

let config;
if (process.env.NODE_ENV === 'development') {
	config = firebaseConfig.dev;
} else {
	config = firebaseConfig.prod;
}

firebase.initializeApp(config);

export default firebase;

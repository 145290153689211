import React, { useState } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { Formik, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { makeNomination } from '../../store/ducks/nominations';

import FormInput from './FormInput';
import { colors } from '../../constants/colors';
import FormButton from './FormButton';

import SuccessMessage from './SuccessMessage';
import LimitErrorMessage from './LimitErrorMessage';

export default function RewindForm({ history }) {
	const dispatch = useDispatch();
	const [isSubmitted, setIsSubmitted] = useState(false);
	const [limitError, setLimitError] = useState('');
	const auth = useSelector((state) => state.auth.user);

	const initialValues = {
		candidate: '',
		reason: '',
	};

	const validationSchema = Yup.object({
		candidate: Yup.string().required('* candidate field must be filled'),
	});

	const handleSubmit = ({ values }) => {
		dispatch(makeNomination(values));
		setIsSubmitted({ isSubmitted: true });
		setTimeout(() => history.push('/votingPage'), 1500);
	};

	if (!auth) return <Redirect to='/signin' />;

	return (
		<Container>
			{isSubmitted ? (
				<SuccessMessage style={{ alignSelf: 'center' }} />
			) : limitError ? (
				<LimitErrorMessage />
			) : (
				<AlignedDiv>
					<RewindInfo>
						<RewindHeading>Rewind Year: 2004 / 05</RewindHeading>

						<RewindSection>
							<RewindText>Football Highlights:</RewindText>
							<RewindHighlights>
								<li>League Champions- Kaizer Chiefs (62 Points)</li>
								<li>Top Goal Scorer- Collins Mbesuma ( Kaizer Chiefs- 25 Goals)</li>
								<li>SAA Supa 8 (MTN8) Champions- SuperSport United</li>
								<li>Coca Cola Cup (Telkom Knockout) Champions- Kaizer Chiefs</li>
								<li>Absa Cup (Nedbank Cup) Champions- SuperSport United</li>
							</RewindHighlights>
						</RewindSection>
					</RewindInfo>

					<InnerContainer>
						<Header>
							<Heading>Enter the name of your candidate</Heading>
						</Header>
						<Formik
							initialValues={initialValues}
							validationSchema={validationSchema}
							onSubmit={(values) => {
								handleSubmit(values);
							}}
						>
							{({ values, handleChange, handleSubmit }) => (
								<FormContainer onSubmit={handleSubmit}>
									<FormGroup>
										<FormInput
											name='candidate'
											placeholder='Enter candidate name'
											inputType='text'
											label='Candidate'
											value={values.candidate}
											onChange={(e) => handleChange(e)}
										/>
										<ErrorMessage name='candidate' component={ErrorText} />
									</FormGroup>
									<FormGroup>
										<ReasonLabel>Reason (optional)</ReasonLabel>
										<ReasonTextArea
											name='reason'
											value={values.reason}
											rows='4'
											cols='50'
											placeholder='Please give reason for voting for this candidate'
											onChange={(e) => handleChange(e)}
										/>
									</FormGroup>
									<FormGroup>
										<FormButton buttonLabel='Submit' type='submit' />
									</FormGroup>
								</FormContainer>
							)}
						</Formik>
					</InnerContainer>
				</AlignedDiv>
			)}
		</Container>
	);
}

const Container = styled.div`
	width: 100%;
	height: 600px;
	margin: 0 auto;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0 30px;
`;

const AlignedDiv = styled.div`
	width: 100%;
	height: 100%;
	margin: 40px auto;
	display: flex;
	align-items: center;
	justify-content: center;
`;

const RewindInfo = styled.div`
	height: auto;
	width: 40%;
	margin: 30px auto;
`;

const RewindSection = styled.div`
	height: auto;
	width: 100%;
	background-color: ${colors.whiteColor};
	margin: 15px 0;
`;

const RewindHeading = styled.h1`
	font-size: 22px;
	font-family: 'Montserrat', sans-serif;
	padding: 20px 0 10px;
	color: ${colors.textColor};
`;

const RewindText = styled.h3`
	font-weight: 600;
`;

const RewindHighlights = styled.ul``;

const InnerContainer = styled.section`
	height: auto;
	width: 40%;
	margin: 30px auto;
	padding: 15px 40px;
	border-radius: 10px;
	background-color: white;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);

	@media screen and (max-width: 1200px) {
		width: 40%;
	}

	@media screen and (max-width: 900px) {
		width: 60%;
	}

	@media screen and (max-width: 768px) {
		width: 60%;
	}

	@media screen and (max-width: 600px) {
		width: 80%;
	}

	@media screen and (max-width: 300px) {
		width: 80%;
	}
`;

const FormContainer = styled.form``;

const FormGroup = styled.div`
	margin: 15px;
`;

const Heading = styled.h1`
	font-size: 22px;
	font-family: 'Montserrat', sans-serif;
	padding: 20px 0 10px 20px;
	color: ${colors.textColor};
`;

const ReasonLabel = styled.label`
	font-size: 16px;
	font-family: 'Montserrat', sans-serif;
	padding-bottom: 5px;
	color: ${colors.textColor};
`;

const ReasonTextArea = styled.textarea`
	width: 100%;
	padding: 10px 10px;
	margin-top: 5px;
	font-size: 16px;
	font-weight: 300;
	font-family: 'Montserrat', sans-serif;
	background-color: #f3f3f3;
	border-radius: 4px;
	border: 0;
	transition: all 250ms ease-in-out;

	&:focus {
		outline: none;
		box-shadow: 0px 0px 3px 0.8px ${colors.primaryColor};
	}
`;

const Header = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
`;

const ErrorText = styled.h2`
	font-size: 14px;
	padding: 8px;
	color: ${colors.redColor};
`;

import firebase from '../../config/firebase';

const db = firebase.firestore();
const fbAuth = firebase.auth();

// Types
const MAKE_NOMINATION = 'MAKE_NOMINATION';
const CAST_VOTE = 'CAST_VOTE';
const FETCH_NOMINATIONS = 'FETCH_NOMINATIONS';
const MAKE_NOMINATION_ERROR = 'MAKE_NOMINATION_ERROR';
const CAST_VOTE_ERROR = 'CAST_VOTE_ERROR';
const FETCH_NOMINATIONS_ERROR = 'FETCH_NOMINATIONS_ERROR';

// Actions

export const Nominate = (nomination) => ({
	type: 'MAKE_NOMINATION',
	payload: nomination,
});

export const castVote = (nominee) => {
	return async (dispatch) => {
		try {
			const currentUserId = await fbAuth.currentUser.uid;
			await db
				.collection('users')
				.doc(currentUserId)
				.get()
				.then((doc) => {
					const user = doc.data();

					let vote;

					db.collection('nominees')
						.add({
							...nominee,
							votes: vote++,
							username: user.username,
							userId: currentUserId,
							createdAt: new Date(),
						})
						.then(() => {
							dispatch({ type: CAST_VOTE, payload: nominee });
						})
						.catch((err) => {
							dispatch({ type: CAST_VOTE_ERROR, payload: err });
						});
				});
		} catch (err) {
			console.error(err);
		}
	};
};

export const makeNomination = (nomination) => {
	return async (dispatch, getState) => {
		try {
			const currentUserId = await fbAuth.currentUser.uid;
			await db
				.collection('users')
				.doc(currentUserId)
				.get()
				.then((doc) => {
					const user = doc.data();

					db.collection('legends')
						.add({
							...nomination,
							username: user.username,
							userId: currentUserId,
							createdAt: new Date(),
						})
						.then((doc) => {
							dispatch({ type: MAKE_NOMINATION, payload: nomination });
						})
						.catch((err) => {
							dispatch({ type: MAKE_NOMINATION_ERROR, payload: err });
						});
				});
		} catch (err) {
			console.error(err);
		}
	};
};

export const listenToNominations = (nominations) => ({
	type: FETCH_NOMINATIONS,
	payload: nominations,
});

export const fetchNominations = () => {
	return async (dispatch, getState) => {
		const currentUser = getState().auth.user;

		await db
			.collection('nominations')
			.where('userId', '==', currentUser)
			.get()
			.then((items) => {
				let nominations = [];

				items.forEach((nomination) => {
					nominations.push(nomination.data());
				});

				dispatch({ type: FETCH_NOMINATIONS, payload: nominations });
			})
			.catch((err) => {
				dispatch({ type: FETCH_NOMINATIONS_ERROR, payload: err });
			});
	};
};

// Reducer
const INITIAL_STATE = {
	nominee: '',
	nominations: [],
	nomination: {},
	err: '',
};

const nominationsReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case MAKE_NOMINATION:
			return {
				...state,
				nomination: action.payload,
			};
		case CAST_VOTE:
			return {
				...state,
				nominee: action.payload,
			};
		case MAKE_NOMINATION_ERROR:
			return {
				...state,
				err: action.payload,
			};
		case FETCH_NOMINATIONS:
			return {
				...state,
				nominations: action.payload,
			};
		case FETCH_NOMINATIONS_ERROR:
			return {
				...state,
				err: action.payload,
			};
		default:
			return state;
	}
};

export { nominationsReducer };

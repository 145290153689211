import React from 'react';
import { NavLink } from 'react-router-dom';

import { colors } from '../../constants/colors';

import LinkButton from '../../components/LinkButton';

export default function SignedOutLinks() {
	return (
		<ul className='right'>
			<li>
				<NavLink to='/' activeStyle={{ color: colors.textColor }}>
					Home
				</NavLink>
			</li>
			<li>
				<NavLink to='/about' activeStyle={{ color: colors.textColor }}>
					About
				</NavLink>
			</li>
			<li>
				<NavLink to='/nominees' activeStyle={{ color: colors.textColor }}>
					Nominees
				</NavLink>
			</li>
			<li>
				<NavLink to='/awards' activeStyle={{ color: colors.textColor }}>
					Categories
				</NavLink>
			</li>
			<li>
				<NavLink to='/contact' activeStyle={{ color: colors.textColor }}>
					Contact
				</NavLink>
			</li>
		</ul>
	);
}

import firebase from '../../config/firebase';

const db = firebase.firestore();

// Types
const MAKE_CONTACT = 'MAKE_CONTACT';
const MAKE_CONTACT_ERROR = 'MAKE_CONTACT_ERROR';

// Actions

export const makeContact = (message) => {
	return async (dispatch) => {
		try {
			await db
				.collection('messages')
				.add({
					...message,
					createdAt: new Date(),
				})
				.then((res) => {
					dispatch({ type: MAKE_CONTACT, payload: message });
				})
				.catch((err) => {
					dispatch({ type: MAKE_CONTACT_ERROR, payload: err });
				});
		} catch (err) {
			console.error(err);
		}
	};
};

// Reducer
const INITIAL_STATE = {
	returnMessage: '',
	contactError: null,
	message: '',
};

const contactReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case MAKE_CONTACT:
			return {
				...state,
				returnMessage: "Thank you, we'll be in touch.",
				message: action.payload,
			};
		case MAKE_CONTACT_ERROR:
			return {
				...state,
				returnMessage: 'Sorry, something went wrong.',
				contactError: action.payload,
			};
		default:
			return state;
	}
};

export { contactReducer };

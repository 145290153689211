import React from 'react';
import styled from 'styled-components';

import { colors } from '../constants/colors';

export default function SportsTypes() {
	return (
		<Container>
			<TypesHeading>FCA '20 Featured Sports</TypesHeading>
			<TypesText>
				In its inaugural season, the <b>Fan Choice Awards</b> will feature awards for the following sports:
			</TypesText>
			<TypesInnerContainer>
				{sports.map((sport) => (
					<TypeWrapper key={sport.id}>
						<TypeIcon src={sport.icon} key={sport.id} alt={sport.name} />
						<TypesInnerText>{sport.name}</TypesInnerText>
					</TypeWrapper>
				))}
			</TypesInnerContainer>
		</Container>
	);
}

const sports = [
	{ id: 0, icon: require('../img/sportsIcons/rugby.svg'), name: 'Rugby' },
	{ id: 1, icon: require('../img/sportsIcons/football.svg'), name: 'Football' },
	{ id: 2, icon: require('../img/sportsIcons/cricket.svg'), name: 'Cricket' },
];

const Container = styled.section`
	height: 80vh;
	background-color: ${colors.secondaryColor};
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;

const TypesHeading = styled.h1`
	font-size: 42px;
	font-weight: 700;
	color: ${colors.whiteColor};
	padding: 20px 0;
	text-align: center;

	@media screen and (max-width: 900px) {
	}

	@media screen and (max-width: 768px) {
	}

	@media screen and (max-width: 600px) {
		font-size: 32px;
	}

	@media screen and (max-width: 300px) {
		font-size: 32px;
	}
`;

const TypesText = styled.h2`
	color: white;
	text-align: center;

	@media screen and (max-width: 900px) {
	}

	@media screen and (max-width: 768px) {
		padding: 0 30px;
	}

	@media screen and (max-width: 600px) {
		padding: 0 30px;
	}

	@media screen and (max-width: 300px) {
		padding: 0 30px;
	}
`;

const TypesInnerContainer = styled.div`
	height: 60vh;
	width: 90%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	margin: 0 auto;
	padding: 30px;

	@media screen and (max-width: 900px) {
	}

	@media screen and (max-width: 768px) {
	}

	@media screen and (max-width: 600px) {
		flex-direction: column;
	}

	@media screen and (max-width: 300px) {
		flex-direction: column;
	}
`;

const TypeWrapper = styled.div`
	display: flex;
	flex-direction: column;
	background-color: ${colors.primaryColor};
	border-radius: 12px;
	width: 220px;
	height: 240px;
	justify-content: center;
	align-items: center;

	@media screen and (max-width: 900px) {
		width: 160px;
		height: 240px;
	}

	@media screen and (max-width: 768px) {
		width: 160px;
		height: 240px;
	}

	@media screen and (max-width: 600px) {
		width: 260px;
		height: 100px;
		flex-direction: row;
	}

	@media screen and (max-width: 300px) {
		width: 260px;
		height: 100px;
		flex-direction: row;
	}
`;

const TypeIcon = styled.img`
	width: 120px;

	@media screen and (max-width: 768px) {
		width: 120px;
	}

	@media screen and (max-width: 600px) {
		width: 90px;
	}

	@media screen and (max-width: 300px) {
		width: 90px;
	}
`;

const TypesInnerText = styled.h2`
	font-size: 26px;
	font-weight: 600;
	color: ${colors.secondaryColor};
	text-align: center;
	padding: 20px 0;
`;

import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import wtfLogo from '../img/wtf_logo.png';
import { colors } from '../constants/colors';

export default function Footer() {
	return (
		<Container>
			<Link to='#'>
				<BrandImage src={wtfLogo} alt='FCA Logo' />
			</Link>
			<Link to='#'>
				<Text>
					Copyright &copy; 2020 <b>WTF</b>. All Rights Reserved.
				</Text>
			</Link>
		</Container>
	);
}

const Container = styled.section`
	min-height: 30vh;
	margin-bottom: 0px;
	background-color: ${colors.primaryColor};
`;

const BrandImage = styled.img`
	width: 140px;
	margin: 0 auto;
	padding: 50px 0 10px;
`;

const Text = styled.h2`
	text-align: center;
	color: ${colors.secondaryColor};
`;

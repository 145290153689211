import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import styled from 'styled-components';

import { colors } from '../constants/colors';

const LinkButton = (props) => {
	const { history, location, match, staticContext, to, onClick, ...rest } = props;
	return (
		<CustomButton
			{...rest}
			onClick={(event) => {
				onClick && onClick(event);
				history.push(to);
			}}
		/>
	);
};

LinkButton.propTypes = {
	to: PropTypes.string.isRequired,
	children: PropTypes.node.isRequired,
};

const CustomButton = styled.button`
	background-color: ${colors.secondaryColor};
	color: ${colors.whiteColor};
	padding: 10px 15px;
	border-radius: 5px;

	&:hover {
		background-color: ${colors.primaryColor};
		color: ${colors.secondaryColor};
	}
`;

export default withRouter(LinkButton);

import React from 'react';
import styled from 'styled-components';

import { colors } from '../constants/colors';

export default function AppLogo() {
	return (
		<Container>
			<BrandImage src={require('../img/fca_logo.png')} alt='FCA Logo' />
			<BrandText>Fan Choice Awards</BrandText>
		</Container>
	);
}

const Container = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
`;

const BrandImage = styled.img`
	width: 60px;
	height: auto;
`;

const BrandText = styled.h1`
	color: ${colors.secondaryColor};
	padding-left: 15px;
	font-weight: 400;
	font-size: 20px;
`;

import React from 'react';
import styled from 'styled-components';

//import Main from '../../components/Main';
import VoteComp from '../../components/votes/VoteComp';
import { colors } from '../../constants/colors';

export default function DashboardPage() {
	return (
		<Container>
			<ContentContainer>
				<Middle>
					<VoteComp />
				</Middle>
			</ContentContainer>
		</Container>
	);
}

const Container = styled.section`
	background-color: ${colors.lightGreyColor};
	height: auto;
	width: 100%;
	margin: 0 auto;
	display: flex;
	flex-direction: column;
`;

const ContentContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	align-self: center;
	margin: 20px 0 30px;

	@media screen and (max-width: 768px) {
		flex-direction: column;
	}

	@media screen and (max-width: 600px) {
		flex-direction: column;
	}

	@media screen and (max-width: 300px) {
		flex-direction: column;
	}
`;

const Middle = styled.div`
	width: 96%;
	border-radius: 5px;
	align-self: center;
`;

import React from 'react';
import styled from 'styled-components';

import { FaLock } from 'react-icons/fa';

import { colors } from '../../constants/colors';

export default function LimitErrorMessage() {
	return (
		<>
			<MessageContainer>
				<MessageText>
					Sorry, voting is closed. Please come back on tomorrow Thursday 01 October, 2020 @ 01:00 am.
				</MessageText>
				<IconContainer>
					<FaLock />
				</IconContainer>
			</MessageContainer>
		</>
	);
}

const MessageContainer = styled.div`
	width: 400px;
	height: 400px;
	background-color: red;
	border-radius: 15px;

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;

const IconContainer = styled.h2`
	font-size: 60px;
	text-align: center;
	font-family: 'Montserrat', sans-serif;
	color: ${colors.whiteColor};
`;

const MessageText = styled.h2`
	font-size: 18px;
	font-weight: 500;
	padding: 10px 25px;
	margin-bottom: 20px;
	font-family: 'Montserrat', sans-serif;
	color: ${colors.whiteColor};
	text-align: center;
`;

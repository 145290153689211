import React from 'react';
import styled from 'styled-components';

import { FaThumbsUp } from 'react-icons/fa';

import { colors } from '../../constants/colors';

export default function SuccessMessage() {
	return (
		<>
			<SuccessContainer>
				<MessageText>Thank you for voting. </MessageText>
				<IconContainer>
					<FaThumbsUp />
				</IconContainer>
			</SuccessContainer>
		</>
	);
}

const SuccessContainer = styled.div`
	width: 400px;
	height: 400px;
	background-color: #f6e05e;
	border-radius: 15px;

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;

const IconContainer = styled.h2`
	font-size: 140px;
	font-family: 'Montserrat', sans-serif;
	padding: 30px 0 10px;
	color: ${colors.secondaryColor};
`;

const MessageText = styled.h2`
	font-size: 40px;
	font-weight: 600;
	text-align: center;
	font-family: 'Montserrat', sans-serif;
	color: ${colors.secondaryColor};
`;

import ReactGA from 'react-ga';

import { trackingCode } from '../config/googleAnalytics';

function init() {
	// Enable debug mode on the local development environment
	const isDev = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';
	ReactGA.initialize(trackingCode, {
		debug: isDev,
		gaOptions: { cookieFlags: 'max-age=7200;secure;samesite=none' },
	});
}

function sendEvent(payload) {
	ReactGA.event(payload);
}

function sendPageview(path) {
	ReactGA.set({ page: path });
	ReactGA.pageview(path);
}

export default {
	init,
	sendEvent,
	sendPageview,
};

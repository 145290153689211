import React from 'react';
import styled from 'styled-components';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';

import { makeContact } from '../../store/ducks/contact';
import FormInput from '../../components/forms/FormInput';
import FormButton from '../../components/forms/FormButton';
import { colors } from '../../constants/colors';

const ContactPage = () => {
	const dispatch = useDispatch();
	const returnMessage = useSelector((state) => state.contact.returnMessage);

	const initialValues = {
		name: '',
		email: '',
		message: '',
	};

	const validationSchema = Yup.object({
		name: Yup.string().required('* field must be filled'),
		email: Yup.string().required('* field must be filled'),
		message: Yup.string().required('* field must be filled'),
	});

	const handleReturnMessage = () => {
		if (returnMessage === 'Sorry, something went wrong.') {
			return <MessageText style={{ color: colors.redColor }}>{returnMessage}</MessageText>;
		} else if (returnMessage === "Thank you, we'll be in touch..") {
			return <MessageText style={{ color: colors.greenColor }}>{returnMessage}</MessageText>;
		} else {
			return null;
		}
	};

	return (
		<Container>
			<div className='contact-hero'>
				<TextBlock style={{ flex: 0.4, padding: 10 }}>
					<MissionText style={{ textAlign: 'right' }}>Wanna chat?</MissionText>
					<BoldText style={{ textAlign: 'right' }}>TO US</BoldText>
				</TextBlock>
			</div>
			<ContactContainer>
				<Left>
					<Heading>Drop us a line:</Heading>
					<ContactBox>
						<ContactText style={{ fontWeight: '500' }}>Tshepiso</ContactText>
						<ContactText>+27 76 111-7634</ContactText>
						<ContactText>tshepi@wearethefan.co.za</ContactText>
					</ContactBox>
					<ContactBox style={{ marginTop: 30 }}>
						<ContactText style={{ fontWeight: '500' }}>Lichaba</ContactText>
						<ContactText>+27 81 319 6087</ContactText>
						<ContactText>lichaba@wearethefan.co.za</ContactText>
					</ContactBox>
				</Left>
				<Right>
					<Heading style={{ color: colors.whiteColor }}>Send us a message:</Heading>
					<Formik
						initialValues={initialValues}
						validationSchema={validationSchema}
						onSubmit={(values) => {
							dispatch(makeContact(values));
						}}
					>
						{({ values, handleChange, handleSubmit }) => (
							<FormContainer onSubmit={handleSubmit}>
								<FormGroup>
									<FormInput
										name='name'
										placeholder='Enter your name'
										inputType='text'
										label='Name'
										value={values.name}
										onChange={(e) => handleChange(e)}
									/>
								</FormGroup>
								<FormGroup>
									<FormInput
										name='email'
										placeholder='Enter your email address'
										inputType='email'
										label='Email'
										value={values.email}
										onChange={(e) => handleChange(e)}
										inputMarginTop={20}
									/>
								</FormGroup>
								<FormGroup>
									<FormTextArea
										name='message'
										value={values.message}
										rows='4'
										cols='50'
										placeholder='Enter your message'
										onChange={(e) => handleChange(e)}
									/>
								</FormGroup>
								<FormGroup>
									<FormButton
										buttonLabel={returnMessage ? <MessageText>{returnMessage}</MessageText> : 'Submit'}
										type='submit'
									/>
								</FormGroup>
								{handleReturnMessage}
							</FormContainer>
						)}
					</Formik>
				</Right>
			</ContactContainer>
		</Container>
	);
};

export default ContactPage;

const Container = styled.div`
	width: 100%;
	height: auto;
	padding: 30px 0;
	margin: 0 auto;
	background-color: #f7fafc;
`;

const ContactContainer = styled.div`
	height: auto;
	width: 96%;
	margin: 60px auto;
	padding: 0 20px;

	display: flex;
	justify-content: space-between;
	align-items: center;

	@media screen and (max-width: 900px) {
		flex-direction: column;
	}

	@media screen and (max-width: 600px) {
		flex-direction: column;
	}

	@media screen and (max-width: 320px) {
		flex-direction: column;
	}
`;

const Left = styled.div`
	width: 60%;
	height: 460px;
	padding: 20px;
	border-radius: 5px;
	background-color: ${colors.whiteColor};

	@media screen and (max-width: 900px) {
		width: 80%;
	}

	@media screen and (max-width: 768px) {
		width: 80%;
	}

	@media screen and (max-width: 600px) {
		width: 90%;
	}

	@media screen and (max-width: 300px) {
		width: 90%;
	}
`;

const Right = styled.div`
	width: 35%;
	height: 460px;
	border-radius: 5px;
	background-color: ${colors.secondaryColor};

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	@media screen and (max-width: 900px) {
		width: 80%;
		margin-top: 20px;
	}

	@media screen and (max-width: 768px) {
		width: 80%;
		margin-top: 20px;
	}

	@media screen and (max-width: 600px) {
		width: 90%;
		margin-top: 20px;
	}

	@media screen and (max-width: 300px) {
		width: 90%;
		margin-top: 20px;
	}
`;

const Heading = styled.h1`
	font-size: 34px;
	font-family: 'Montserrat', sans-serif;
	font-weight: 600;
	padding: 10px 20px;
	text-align: center;
	color: ${colors.secondaryColor};
`;

const ContactBox = styled.div`
	padding-left: 20px;
`;

const ContactText = styled.h2`
	font-size: 16px;
	padding: 5px;
	color: #2d3748;
`;

const TextBlock = styled.div`
	width: auto;
`;

const BoldText = styled.h2`
	font-size: 44px;
	font-family: 'Montserrat', sans-serif;
	font-weight: 600;
	text-transform: uppercase;
	color: white;

	@media screen and (max-width: 900px) {
		font-size: 44px;
	}

	@media screen and (max-width: 768px) {
		font-size: 32px;
	}

	@media screen and (max-width: 600px) {
		font-size: 32px;
	}

	@media screen and (max-width: 300px) {
		font-size: 32px;
	}
`;

const MissionText = styled.h2`
	font-size: 28px;
	font-family: 'Montserrat', sans-serif;
	color: white;

	@media screen and (max-width: 900px) {
		font-size: 28px;
	}

	@media screen and (max-width: 768px) {
		font-size: 18px;
	}

	@media screen and (max-width: 600px) {
		font-size: 18px;
	}

	@media screen and (max-width: 300px) {
		font-size: 18px;
	}
`;

const FormContainer = styled.form`
	width: 80%;
	height: 330px;
	padding: 5px 10px;
`;

const FormGroup = styled.div`
	margin: 15px;
`;

const FormTextArea = styled.textarea`
	width: 100%;
	padding: 10px 10px;
	margin-top: 5px;
	font-size: 16px;
	font-weight: 300;
	font-family: 'Montserrat', sans-serif;
	background-color: #f3f3f3;
	border-radius: 4px;
	border: 0;
	transition: all 250ms ease-in-out;

	&:focus {
		outline: none;
		box-shadow: 0px 0px 3px 0.8px ${colors.primaryColor};
	}
`;

const MessageText = styled.h2`
	font-size: 16px;
`;

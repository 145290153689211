import React from 'react';
import styled from 'styled-components';
import { FiExternalLink } from 'react-icons/fi';

export default function Banner() {
	return (
		<Covid19BannerContainer>
			<BannerLeft>
				<MainText>
					<b>COVID19</b>: Please stay home. If you have to go out, please wash your hands, wear a mask and observe
					social distancing.
				</MainText>
			</BannerLeft>
			<BannerRight>
				<LinkText flexDirection='row'>Read more...</LinkText>
				<a href='https://sacoronavirus.co.za/'>
					<FiExternalLink />
				</a>
			</BannerRight>
		</Covid19BannerContainer>
	);
}

const Covid19BannerContainer = styled.div`
	width: 100%;
	padding: 5px;
	background-color: #f6e05e;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;

	@media screen and (max-width: 900px) {
		flex-direction: column;
		align-items: center;
	}

	@media screen and (max-width: 768px) {
		flex-direction: column;
		align-items: center;
	}

	@media screen and (max-width: 600px) {
		padding: 10px;
		flex-direction: column;
		justify-content: center;
	}

	@media screen and (max-width: 300px) {
		padding: 10px;
		flex-direction: column;
		justify-content: center;
	}
`;

const BannerLeft = styled.div`
	display: flex;
	justify-content: flex-end;
	padding-right: 5px;

	@media screen and (max-width: 1200px) {
		align-items: center;
		justify-content: flex-end;
	}

	@media screen and (max-width: 900px) {
		align-items: center;
		text-align: center;
	}

	@media screen and (max-width: 768px) {
		align-items: center;
		text-align: center;
	}

	@media screen and (max-width: 600px) {
		align-items: center;
		text-align: center;
	}

	@media screen and (max-width: 300px) {
		align-items: center;
		text-align: center;
	}
`;

const MainText = styled.h2`
	font-size: 14px;
	font-weight: 400;
`;

const LinkText = styled.h2`
	font-size: 14px;
	font-weight: 600;
	padding-right: 5px;
`;

const BannerRight = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;

	@media screen and (max-width: 900px) {
		justify-content: center;
	}

	@media screen and (max-width: 768px) {
		justify-content: center;
	}

	@media screen and (max-width: 600px) {
		justify-content: center;
	}

	@media screen and (max-width: 300px) {
		justify-content: center;
	}
`;

import React from 'react';
import styled from 'styled-components';

import nafsa_logo from '../img/nafsa_logo.png';
import { colors } from '../constants/colors';

export default function Partnership() {
	return (
		<Container>
			<a href='https://nafsa.org.za/'>
				<NAFSALogo src={nafsa_logo} alt='NAFSA logo' />
			</a>
			<PartnerTextContainer>
				<PartnershipText>
					The <b>Fan Choice Awards</b> holds dear its partnership with the{' '}
					<a href='https://nafsa.org.za/'>
						<b>National African Football Supporters' Association</b>
					</a>{' '}
					(<b>NAFSA</b>). Together, we are amplifying the voice of the fan and changing the landscape of South-African
					sport.
				</PartnershipText>
			</PartnerTextContainer>
		</Container>
	);
}

const Container = styled.section`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	height: 80vh;
	width: auto;

	@media screen and (max-width: 900px) {
	}

	@media screen and (max-width: 768px) {
		flex-direction: column;
		padding: 0 20px;
	}

	@media screen and (max-width: 600px) {
		flex-direction: column;
		padding: 0 20px;
	}

	@media screen and (max-width: 300px) {
		flex-direction: column;
		padding: 0 20px;
	}
`;

const NAFSALogo = styled.img`
	width: 200px;

	@media screen and (max-width: 900px) {
		width: 200px;
	}

	@media screen and (max-width: 768px) {
		width: 160px;
	}

	@media screen and (max-width: 600px) {
		width: 140px;
	}

	@media screen and (max-width: 300px) {
		width: 140px;
	}
`;

const PartnerTextContainer = styled.div`
	width: 400px;
	margin-left: 100px;
	text-align: center;

	@media screen and (max-width: 900px) {
		margin-left: 100px;
	}

	@media screen and (max-width: 768px) {
		margin-left: 50px;
	}

	@media screen and (max-width: 600px) {
		margin-left: 0;
		margin-top: 20px;
	}

	@media screen and (max-width: 300px) {
		margin-left: 0;
		margin-top: 20px;
	}
`;

const PartnershipText = styled.h2`
	line-height: 1.6;
	font-size: 22px;
	font-weight: 500;
	color: ${colors.secondaryColor};
`;

import firebase from '../../config/firebase';

const db = firebase.firestore();
const fbAuth = firebase.auth();

const CAST_VOTE = 'CAST_VOTE';
const FETCH_CATEGORIES = 'FETCH_CATEGORIES';
const CAST_VOTE_ERROR = 'CAST_VOTE_ERROR';
const FETCH_CATEGORIES_ERROR = 'FETCH_CATEGORIES_ERROR';

export const fetchNominees = () => {
	return async (dispatch) => {
		await db
			.collection('categories')
			.get()
			.then((doc) => {
				let categories = [];

				doc.forEach((nominee) => {
					categories.push(nominee.data());
				});

				console.log('-------------action----data----------------------------', categories);

				dispatch({ type: FETCH_CATEGORIES, payload: categories });
			})
			.catch((err) => {
				dispatch({ type: FETCH_CATEGORIES_ERROR, payload: err });
			});
	};
};

export const castVote = (nominee) => {
	return async (dispatch) => {
		try {
			const currentUserId = await fbAuth.currentUser.uid;

			await db
				.collection('nominees')
				.doc(currentUserId)
				.get()
				.then((doc) => {
					const user = doc.data();

					let votes;

					db.collection('nominees')
						.add({
							...nominee,
							votes: votes++,
							username: user.username,
							userId: currentUserId,
							createdAt: new Date(),
						})
						.then((doc) => {
							dispatch({ type: CAST_VOTE, payload: nominee });
						})
						.catch((err) => {
							dispatch({ type: CAST_VOTE_ERROR, payload: err });
						});
				});
		} catch (err) {
			console.error(err);
		}
	};
};

const INITIAL_STATE = {
	vote: '',
	nominees: [],
	categories: [],
	err: null,
};

const votingReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case CAST_VOTE:
			return {
				...state,
				vote: action.payload,
			};
		case FETCH_CATEGORIES:
			return {
				...state,
				categories: action.payload,
			};
		case CAST_VOTE_ERROR:
			return {
				...state,
				err: action.payload,
			};
		case FETCH_CATEGORIES_ERROR:
			return {
				...state,
				err: action.payload,
			};
		default:
			return state;
	}
};

export { votingReducer };

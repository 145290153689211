import React from 'react';
import styled from 'styled-components';
import { IoMdCloseCircle } from 'react-icons/io';
import { useDispatch } from 'react-redux';
import { Formik, ErrorMessage } from 'formik';
import * as Yup from 'yup';

import { makeNomination } from '../../store/ducks/nominations';

import FormInput from './FormInput';
import { colors } from '../../constants/colors';
import FormButton from './FormButton';

export default function NominationForm({ onHide }) {
	const dispatch = useDispatch();
	const initialValues = {
		sport: '',
		category: '',
		candidate: '',
		reason: '',
	};

	const validationSchema = Yup.object({
		candidate: Yup.string().required('* candidate field must be filled'),
		category: Yup.string().required('* please choose a category'),
		sport: Yup.string().required('* please choose a sport'),
	});

	return (
		<Container>
			<Header>
				<Heading>Nomination Form</Heading>
				<IconContainer onClick={onHide}>
					<IoMdCloseCircle />
				</IconContainer>
			</Header>
			<Formik
				initialValues={initialValues}
				validationSchema={validationSchema}
				onSubmit={(values) => {
					dispatch(makeNomination(values));
					onHide();
				}}
			>
				{({ values, handleChange, handleSubmit }) => (
					<FormContainer onSubmit={handleSubmit}>
						<FormGroup style={{ marginTop: 20 }}>
							<CategorySelect value={values.sport} onChange={(e) => handleChange(e)} name='sport'>
								<option value=''>Select a sport</option>
								<option value='football'>Football</option>
								<option value='cricket'>Cricket</option>
								<option value='rugby'>Rugby</option>
								<option value='all'>All Sports</option>
							</CategorySelect>
							<ErrorMessage name='sport' component={ErrorText} />
						</FormGroup>
						<FormGroup>
							<CategorySelect value={values.category} onChange={(e) => handleChange(e)} name='category'>
								<option value=''>Select a category</option>
								<option value='fan'>Fan of the Year</option>
								<option value='player'>Player of the Year</option>
								<option value='coach'>Coach of the Year</option>
								<option value='team'>Team of the Year</option>
								<option value='administrator'>Administrator of the Year</option>
								<option value='young'>Young Player of the Year</option>
								<option value='branch'>Supporters' Branch of the Year</option>
							</CategorySelect>
							<ErrorMessage name='category' component={ErrorText} />
						</FormGroup>
						<FormGroup>
							<FormInput
								name='candidate'
								placeholder='Enter candidate name'
								inputType='text'
								label='Candidate'
								value={values.candidate}
								onChange={(e) => handleChange(e)}
							/>
							<ErrorMessage name='candidate' component={ErrorText} />
						</FormGroup>
						<FormGroup>
							<ReasonLabel>Reason (optional)</ReasonLabel>
							<ReasonTextArea
								name='reason'
								value={values.reason}
								rows='4'
								cols='50'
								placeholder='Please give reason for voting for this candidate'
								onChange={(e) => handleChange(e)}
							/>
						</FormGroup>
						<FormGroup>
							<FormButton buttonLabel='Submit' type='submit' />
						</FormGroup>
					</FormContainer>
				)}
			</Formik>
		</Container>
	);
}

const Container = styled.section`
	position: relative;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	height: auto;
	width: 40%;
	padding: 15px 40px;
	border-radius: 10px;
	background-color: white;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
	z-index: 1000;

	@media screen and (max-width: 1200px) {
		width: 40%;
	}

	@media screen and (max-width: 900px) {
		width: 40%;
	}

	@media screen and (max-width: 768px) {
		width: 60%;
		top: 60%;
	}

	@media screen and (max-width: 600px) {
		width: 80%;
		top: 60%;
	}

	@media screen and (max-width: 300px) {
		width: 80%;
		top: 60%;
	}
`;

const FormContainer = styled.form``;

const FormGroup = styled.div`
	margin: 15px;
`;

const Heading = styled.h1`
	font-size: 22px;
	font-family: 'Montserrat', sans-serif;
	padding: 20px 0 10px;
	color: ${colors.textColor};
`;

const ReasonLabel = styled.label`
	font-size: 16px;
	font-family: 'Montserrat', sans-serif;
	padding-bottom: 5px;
	color: ${colors.textColor};
`;

const ReasonTextArea = styled.textarea`
	width: 100%;
	padding: 10px 10px;
	margin-top: 5px;
	font-size: 16px;
	font-weight: 300;
	font-family: 'Montserrat', sans-serif;
	background-color: #f3f3f3;
	border-radius: 4px;
	border: 0;
	transition: all 250ms ease-in-out;

	&:focus {
		outline: none;
		box-shadow: 0px 0px 3px 0.8px ${colors.primaryColor};
	}
`;

const CategorySelect = styled.select`
	width: 100%;
	padding: 10px;
	margin-top: 5px;
	font-size: 16px;
	font-weight: 300;
	font-family: 'Montserrat', sans-serif;
	background-color: #f3f3f3;
	border-radius: 4px;
	border: 0;
	transition: all 250ms ease-in-out;

	&:select {
		background-color: crimson;
	}

	&:focus {
		outline: none;
		box-shadow: 0px 0px 3px 0.8px ${colors.primaryColor};
	}
`;

const Header = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
`;

const IconContainer = styled.button`
	font-size: 34px;
	font-family: 'Montserrat', sans-serif;
	padding: 30px 0 10px;
	color: ${colors.secondaryColor};
`;

const ErrorText = styled.h2`
	font-size: 14px;
	padding: 8px;
	color: ${colors.redColor};
`;

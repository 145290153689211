import React from 'react';
import styled from 'styled-components';

import { colors } from '../../constants/colors';

import {
	fan_of_the_year,
	team_of_the_year,
	player_of_the_year,
	coach_of_the_year,
	supporters_branch_of_the_year,
	national_team_of_the_year,
	young_player_of_the_year,
	administrator_of_the_year,
} from '../../data/finalists';

const Card = ({ item }) => {
	const { finalist_name, logo, sport } = item;
	return (
		<CardContainer>
			<CardHeader>
				<CardHeaderText>Sport: {sport}</CardHeaderText>
			</CardHeader>
			{logo ? (
				<CardContentAlt>
					<CardContentText>{finalist_name}</CardContentText>
					<VerticalLine />
					<CardImage src={logo} />
				</CardContentAlt>
			) : (
				<CardContent>
					<CardContentText style={{ fontSize: 24, textAlign: 'center', paddingTop: 30 }}>
						{finalist_name}
					</CardContentText>
				</CardContent>
			)}
		</CardContainer>
	);
};

export default function FinalistsPage() {
	return (
		<Container>
			<PageHeading>Congratulations for the first ever FCA winners</PageHeading>
			<CategoryGroup>
				<CategoryHeading>Fan Of The Year</CategoryHeading>
				<CategoryContainer>
					{fan_of_the_year.map((item) => (
						<Card item={item} />
					))}
				</CategoryContainer>
			</CategoryGroup>
			<CategoryGroup>
				<CategoryHeading>Player Of The Year</CategoryHeading>
				<CategoryContainer>
					{player_of_the_year.map((item) => (
						<Card item={item} />
					))}
				</CategoryContainer>
			</CategoryGroup>
			<CategoryGroup>
				<CategoryHeading>Coach Of The Year Winner</CategoryHeading>
				<CategoryContainer>
					{coach_of_the_year.map((item) => (
						<Card item={item} />
					))}
				</CategoryContainer>
			</CategoryGroup>
			<CategoryGroup>
				<CategoryHeading>Team Of The Year Winner</CategoryHeading>
				<CategoryContainer>
					{team_of_the_year.map((item) => (
						<Card item={item} />
					))}
				</CategoryContainer>
			</CategoryGroup>
			<CategoryGroup>
				<CategoryHeading>Supporter's Branch Of The Year Winner</CategoryHeading>
				<CategoryContainer>
					{supporters_branch_of_the_year.map((item) => (
						<Card item={item} />
					))}
				</CategoryContainer>
			</CategoryGroup>
			<CategoryGroup>
				<CategoryHeading>Administrator Of The Year Winner</CategoryHeading>
				<CategoryContainer>
					{administrator_of_the_year.map((item) => (
						<Card item={item} />
					))}
				</CategoryContainer>
			</CategoryGroup>
			<CategoryGroup>
				<CategoryHeading>Young Player Of The Year Winner</CategoryHeading>
				<CategoryContainer>
					{young_player_of_the_year.map((item) => (
						<Card item={item} />
					))}
				</CategoryContainer>
			</CategoryGroup>
			<CategoryGroup>
				<CategoryHeading>National Team Of The Year Winner</CategoryHeading>
				<CategoryContainer>
					{national_team_of_the_year.map((item) => (
						<Card item={item} />
					))}
				</CategoryContainer>
			</CategoryGroup>
		</Container>
	);
}

const Container = styled.div`
	width: 100%;
	padding: 30px 0;
	background-color: #f7fafc;
`;

const CategoryHeading = styled.h2`
	font-size: 24px;
	color: ${colors.secondaryColor};
	font-family: 'Montserrat', sans-serif;
	font-weight: 500;
	padding: 20px 0 0 40px;
`;

const PageHeading = styled.h1`
	font-size: 34px;
	font-family: 'Montserrat', sans-serif;
	font-weight: 600;
	padding: 40px 0 20px 40px;
	text-align: center;
	color: ${colors.secondaryColor};
`;

const CategoryGroup = styled.div`
	width: 100%;
	height: auto;
`;

const CategoryContainer = styled.div`
	margin: 30px auto;
	padding: 0 20px;

	display: flex;
	flex-wrap: wrap;
	align-content: flex-start;
	justify-content: space-evenly;
`;

// Card

const CardContainer = styled.div`
	height: 140px;
	width: 360px;
	margin: 20px 0;
	border-radius: 10px;
	background-color: white;
`;

const CardHeader = styled.div`
	height: 25%;
	border-top-right-radius: 10px;
	border-top-left-radius: 10px;
	background-color: #ebedf0;
	display: flex;
	align-items: center;
	justify-content: center;
`;

const CardContent = styled.div`
	height: 80%;
	width: 100%;
	border-bottom-right-radius: 10px;
	border-bottom-left-radius: 10px;
`;

const CardContentAlt = styled.div`
	height: 80%;
	display: flex;
	align-items: center;
	justify-content: space-around;
	padding: 0 20px;
	border-bottom-right-radius: 10px;
	border-bottom-left-radius: 10px;
`;

const CardHeaderText = styled.h3`
	color: #2d3748;
	font-size: 18px;
	font-weight: 600;
`;

const CardContentText = styled.h4`
	color: #2d3748;
	font-size: 22px;
	font-weight: 600;
`;

const CardImage = styled.img`
	height: 80px;
	width: auto;
	margin-left: 5px;
`;

const VerticalLine = styled.div`
	width: 0px;
	height: 80%;
	float: left;
	border: 1px inset;
`;

import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { FaHeart } from 'react-icons/fa';

import { colors } from '../constants/colors';

export default function News() {
	return (
		<Container>
			<CountdownHeading>FCA Voting window is now closed.</CountdownHeading>
			<MiddleText>
				Visit our{' '}
				<Link to='nominees' style={{ color: colors.primaryColor }}>
					winners' page
				</Link>{' '}
				to see who fans voted for.
			</MiddleText>
			<Box style={{ marginTop: 20 }}>
				<CountdownText>What's next from WTF?</CountdownText>
			</Box>
			<Box style={{ flexDirection: 'row' }}>
				<AppImage src={require('../img/fantstq_logo.png')} />
				<MottoBox>
					<FantstqText>Fantstq</FantstqText>
					<MottoContainer>
						<MottoText style={{ marginRight: 5 }}>Do what fans</MottoText>
						<FaHeart style={{ color: 'crimson' }} />
						<MottoText style={{ marginLeft: 5 }}>to do.</MottoText>
					</MottoContainer>
				</MottoBox>
			</Box>
			<CountdownText style={{ color: colors.whiteColor }}>Coming soon.</CountdownText>
		</Container>
	);
}

const Container = styled.section`
	height: 80vh;
	display: flex;
	background-color: ${colors.secondaryColor};
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;

const Box = styled.div`
	width: 100%;
	margin: 10px auto;
	display: flex;
	align-items: center;
	justify-content: center;
`;

const CountdownHeading = styled.h1`
	font-size: 34px;
	font-weight: 700;
	color: ${colors.whiteColor};
`;

const MiddleText = styled.h1`
	font-size: 24px;
	font-weight: 500;
	color: ${colors.whiteColor};
`;

const CountdownText = styled.h1`
	font-size: 24px;
	font-weight: 500;
	color: ${colors.whiteColor};
`;

const AppImage = styled.img`
	height: 140px;
	width: undefined;
`;

const FantstqText = styled.h2`
	font-size: 70px;
	font-weight: 600;
	font-family: 'Bebas Neue', cursive;
	color: ${colors.whiteColor};
`;

const MottoBox = styled.div`
	width: 300px;
	margin-left: 10px;
	display: flex;
	flex-direction: column;
`;

const MottoText = styled.h2`
	font-size: 18px;
	font-weight: 400;
	font-family: 'Montserrat', sans-serif;
	color: ${colors.whiteColor};
	flex-direction: row;
`;

const MottoContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-top: -25px;
`;

import React, { useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

import SuccessMessage from './SuccessMessage';
import LimitErrorMessage from './LimitErrorMessage';
import firebase from '../../config/firebase';

const VotingForm2 = ({ history }) => {
	const [name, setName] = useState('');
	const [limitError, setLimitError] = useState('');
	const [isSubmitted, setIsSubmitted] = useState(false);
	const auth = useSelector((state) => state.auth.user);
	const isDisabled = name === '';

	const formSubmit = async (event) => {
		event.preventDefault();

		await firebase
			.firestore()
			.collection('users')
			.where('id', '==', auth)
			.get()
			.then((snapshot) => {
				snapshot.forEach((doc) => {
					if (doc.exists) {
						const vote = {
							nominee: name,
							votes: firebase.firestore.FieldValue.increment(1),
							createdAt: firebase.firestore.Timestamp.now(),
						};

						firebase
							.firestore()
							.collection('fans')
							.doc(name)
							.update(vote)
							.then(() => {
								setName({
									name,
								});
								setIsSubmitted({ isSubmitted: true });

								setTimeout(() => history.push('/votingPage'), 1500);
							})
							.catch((err) => {
								console.log(err);
								setLimitError({ limitError: err });
								setTimeout(() => history.push('/votingPage'), 2000);
							});
					}
				});
			});
	};

	if (!auth) return <Redirect to='/signin' />;

	return (
		<Container>
			{isSubmitted ? (
				<SuccessMessage />
			) : limitError ? (
				<LimitErrorMessage />
			) : (
				<InnerWrapper>
					<FormHeading>Fan of the Year Nominees</FormHeading>

					<form
						style={{ flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}
						onSubmit={formSubmit}
					>
						<div className='radio' style={{ marginBottom: 30 }}>
							<input
								type='radio'
								value='Bullie James'
								checked={name === 'Bullie James'}
								onChange={(e) => {
									setName(e.target.value);
								}}
							/>
							<NomineeLabel>Bullie James</NomineeLabel>
						</div>

						<div className='radio' style={{ marginBottom: 30 }}>
							<input
								type='radio'
								value='Zama K. Ngcobo'
								checked={name === 'Zama K. Ngcobo'}
								onChange={(e) => {
									setName(e.target.value);
								}}
							/>
							<NomineeLabel>Zama K. Ngcobo</NomineeLabel>
						</div>

						<div className='radio' style={{ marginBottom: 30 }}>
							<input
								type='radio'
								value='Lerato Sharon'
								checked={name === 'Lerato Sharon'}
								onChange={(e) => {
									setName(e.target.value);
								}}
							/>
							<NomineeLabel>Lerato Sharon</NomineeLabel>
						</div>

						<div className='radio' style={{ marginBottom: 30 }}>
							<input
								type='radio'
								value='Itumeleng'
								checked={name === 'Itumeleng'}
								onChange={(e) => {
									setName(e.target.value);
								}}
							/>
							<NomineeLabel>Itumeleng</NomineeLabel>
						</div>

						<div className='radio' style={{ marginBottom: 30 }}>
							<input
								type='radio'
								value='Lufuno'
								checked={name === 'Lufuno'}
								onChange={(e) => {
									setName(e.target.value);
								}}
							/>
							<NomineeLabel>Lufuno</NomineeLabel>
						</div>

						<div className='radio' style={{ marginBottom: 30 }}>
							<input
								type='radio'
								value='GAF President'
								checked={name === 'GAF President'}
								onChange={(e) => {
									setName(e.target.value);
								}}
							/>
							<NomineeLabel>GAF President</NomineeLabel>
						</div>

						<SubmitButton disabled={isDisabled} type='submit'>
							Submit
						</SubmitButton>
					</form>
					{limitError && (
						<ErrorText>
							Sorry, you have reached your voting limit. You are only allowed 5 votes per day. Please come back tomorrow
						</ErrorText>
					)}
				</InnerWrapper>
			)}
		</Container>
	);
};

const Container = styled.div`
	height: 700px;
	margin: 40px auto;
	min-height: 400px;
	display: flex;
	justify-content: center;
`;

const InnerWrapper = styled.div`
	width: 600px;
	display: flex;
	align-items: center;
	flex-direction: column;
`;

const FormHeading = styled.h2`
	margin: 30px 0;
	font-size: 32px;
	font-weight: 600;
`;

const NomineeLabel = styled.label`
	margin-left: 30px;
	font-size: 18px;
	font-weight: 600;
`;

const SubmitButton = styled.button`
	color: #2d3748;
	background-color: #f6e05e;
	padding: 8px 70px;
	border-radius: 5px;
	font-weight: 600;
	margin-top: 20px;
	outline: none;
`;

const ErrorText = styled.h2`
	margin: 20px 100px;
	font-size: 16px;
	font-weight: 600;
	color: red;
	text-align: center;
`;

export default VotingForm2;

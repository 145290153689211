import React from 'react';
import styled from 'styled-components';

import { colors } from '../../constants/colors';

const AboutPage = () => {
	return (
		<Container>
			<div className='about-hero'>
				<TextBlock style={{ flex: 0.4, padding: 10 }}>
					<MissionText style={{ textAlign: 'right' }}>We are on a</MissionText>
					<Heading style={{ textAlign: 'right' }}>Mission</Heading>
				</TextBlock>
				<TextBlock style={{ flex: 0.1 }}>
					<MiddleText style={{ textAlign: 'center' }}>to</MiddleText>
				</TextBlock>
				<TextBlock style={{ flex: 0.4 }}>
					<Heading>Elevate</Heading>
					<MissionText>the role of sports fans.</MissionText>
				</TextBlock>
			</div>

			<PageHeading>About the FCA</PageHeading>
			<ContentBody>
				<LeftBox>
					<PageText>
						This project sprouted from a long standing challenge that fans have very little impact on much of what
						happens in the sports industry, they are supposedly the center of it all.
					</PageText>
					<PageText>
						After years of watching on the sidelines, we established the Fan Choice Awards. Our aim is to arm fans with
						the power to vote and reward athletes that have entertained and impressed them during the previous year of
						sport. This extends to teams, administrator and fan focused elements.  
					</PageText>

					<PageText>
						The FCA is for sports fans from all walks of life and all sporting codes. However, in its inaugural year,
						the project focuses top 3 sports in South-Africa, that is soccer, rugby and cricket. In each category fans
						will be able to vote in awards that are focused on rewarding three key roles of any sport. We will gradually
						add Athletics, Netball, Golf, Basketball and more sports for both able bodied and people with disabilities.
					</PageText>
					<PageText>
						The FCAs will live on the digital space through its website, and it will also have a strong presence on all
						known social networks that are frequented by sports fans.
					</PageText>
				</LeftBox>

				<RightBox>
					<PageText style={{ color: colors.whiteColor, padding: 20 }}>
						The Fan Choice Awards were established as an outlet for South-African sports fans to express themselves
						through the power of the vote. The project has two central goals:
					</PageText>
					<GoalsBox>
						<GoalsContainer>
							<GoalsText>Amplify</GoalsText>
							<GoalsText style={{ fontWeight: '400', marginTop: 5 }}>the fan voice</GoalsText>
						</GoalsContainer>
						<GoalsContainer>
							<GoalsText>Provide</GoalsText>
							<GoalsText style={{ fontWeight: '400', marginTop: 5 }}>fan entertainment</GoalsText>
						</GoalsContainer>
					</GoalsBox>
				</RightBox>
			</ContentBody>
		</Container>
	);
};

export default AboutPage;

const Container = styled.div`
	width: 100%;
	padding: 30px 0;
	background-color: #f7fafc;
`;

const TextBlock = styled.div`
	width: auto;
`;

const ContentBody = styled.div`
	width: 100%;
	height: auto;
	margin: 40px 0;
	padding: 0 20px;
	display: flex;
	justify-content: space-between;

	@media screen and (max-width: 900px) {
		flex-direction: column;
	}

	@media screen and (max-width: 768px) {
		flex-direction: column;
	}

	@media screen and (max-width: 600px) {
		flex-direction: column;
	}

	@media screen and (max-width: 300px) {
		flex-direction: column;
	}
`;

const LeftBox = styled.div`
	flex: 0.6;
	border-radius: 5px;
	display: flex;
	flex-direction: column;
	align-items: center;
`;

const RightBox = styled.div`
	flex: 0.3;
	border-radius: 5px;
	display: flex;
	flex-direction: column;
	background-color: ${colors.secondaryColor};
	box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
`;

const PageHeading = styled.h1`
	font-size: 34px;
	font-family: 'Montserrat', sans-serif;
	font-weight: 600;
	padding: 40px 0 20px 40px;
	text-transform: capitalize;
	color: ${colors.secondaryColor};
`;

const Heading = styled.h2`
	font-size: 44px;
	font-family: 'Montserrat', sans-serif;
	font-weight: 600;
	text-transform: uppercase;
	color: white;

	@media screen and (max-width: 900px) {
		font-size: 44px;
	}

	@media screen and (max-width: 768px) {
		font-size: 32px;
	}

	@media screen and (max-width: 600px) {
		font-size: 32px;
	}

	@media screen and (max-width: 300px) {
		font-size: 32px;
	}
`;

const MissionText = styled.h2`
	font-size: 28px;
	font-family: 'Montserrat', sans-serif;
	color: white;

	@media screen and (max-width: 900px) {
		font-size: 28px;
	}

	@media screen and (max-width: 768px) {
		font-size: 18px;
	}

	@media screen and (max-width: 600px) {
		font-size: 18px;
	}

	@media screen and (max-width: 300px) {
		font-size: 18px;
	}
`;

const PageText = styled.h2`
	font-size: 16px;
	font-family: 'Montserrat', sans-serif;
	line-height: 1.6;
	padding: 16px;
	color: ${colors.secondaryColor};
`;

const MiddleText = styled.h2`
	font-family: 'Playball', cursive;
	font-size: 62px;
	line-height: 1.2;
	padding: 16px;
	color: ${colors.whiteColor};
`;

const GoalsBox = styled.div`
	width: 100%;
	height: auto;
	margin: 0 auto;
	padding: 20px;

	display: flex;
	flex-direction: column;
`;

const GoalsContainer = styled.div`
	flex: 0.4;
	padding: 20px 0;
	border-radius: 5px;
	margin: 10px 0;
	background-color: ${colors.primaryColor};
	box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;

const GoalsText = styled.h2`
	font-size: 28px;
	line-height: 1.6;
	font-family: 'Montserrat', sans-serif;
	font-weight: 600;
	text-align: center;

	color: ${colors.secondaryColor};
`;

import React from 'react';
import { Route, withRouter, Switch } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import LandingPage from './pages/LandingPage';
import DashboardPage from './pages/dashboard/DashboardPage';
import SignUpPage from './pages/auth/SignUpPage';
import SignInPage from './pages/auth/SignInPage';
import AboutPage from './pages/layout/AboutPage';
import AwardsPage from './pages/layout/AwardsPage';
import ContactPage from './pages/layout/ContactPage';
import FinalistsPage from './pages/layout/FinalistsPage';

import Navbar from './components/Navbar';
import Footer from './components/Footer';
import VoteComp from './components/votes/VoteComp';
import NominationForm from './components/forms/NominationForm';

import useGoogleAnalytics from './hooks/useGoogleAnalytics';
//import NewsPage from './pages/layout/NewsPage';
import VotingForm from './components/forms/VotingForm';
import VotingForm2 from './components/forms/VotingForm2';
import RewindForm from './components/forms/RewindForm';

function Routes() {
	useGoogleAnalytics();

	return (
		<main>
			<Helmet>
				<meta charSet='UTF-8' />
				<title>FCA | Fan Choice Awards</title>
				<meta
					name='description'
					content='The Fan Choice Awards gives sports fans the power to nominate and vote for their favourite football, cricket and rugby players, coaches and teams that have entertained and impressed them during the past 12-months of sport.'
				/>
			</Helmet>
			<Switch>
				<Route path='/' exact component={LandingPage} />
				<Route path='/nominations' exact component={DashboardPage} />
				<Route path='/awards' exact component={AwardsPage} />
				<Route path='/nominees' exact component={FinalistsPage} />
				<Route path='/votingPage' exact component={VoteComp} />
				<Route path='/category1' exact component={VotingForm} />
				<Route path='/category2' exact component={VotingForm2} />
				<Route path='/category3' exact component={RewindForm} />
				<Route path='/about' component={AboutPage} />
				<Route path='/contact' component={ContactPage} />
				<Route path='/nominate' components={NominationForm} />
				<Route path='/signin' component={SignInPage} />
				<Route path='/register' component={SignUpPage} />
			</Switch>
		</main>
	);
}

function App({ location }) {
	return (
		<div>
			{location.pathname !== '/signin' && location.pathname !== '/register' && <Navbar />}
			<Routes />
			{location.pathname !== '/signin' && location.pathname !== '/register' && <Footer />}
		</div>
	);
}

export default withRouter(App);

import React from 'react';
import { NavLink } from 'react-router-dom';
import HeadRoom from 'react-headroom';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import Covid19Banner from './Covid19Banner';
import { colors } from '../constants/colors';

import AppLogo from './AppLogo';
import SignedInLinks from '../pages/layout/SignedInLinks';
import SignedOutLinks from '../pages/layout/SignedOutLinks';

const Navbar = () => {
	const authUser = useSelector((state) => state.auth.user);

	return (
		<HeadRoom>
			<Covid19Banner />
			<Container>
				<NavLink to={authUser ? 'nominations' : '/'}>
					<AppLogo />
				</NavLink>
				{authUser && <SignedInLinks />}
				{!authUser && <SignedOutLinks />}
			</Container>
		</HeadRoom>
	);
};

const Container = styled.div`
	height: 60px;
	width: 100%;
	padding: 10px 30px;
	background-color: ${colors.whiteColor};

	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;

	@media screen and (max-width: 900px) {
		height: 100px;
		flex-direction: column;
	}

	@media screen and (max-width: 700px) {
		height: 100px;
		flex-direction: column;
	}

	@media screen and (max-width: 600px) {
		height: 100px;
		flex-direction: column;
	}

	@media screen and (max-width: 300px) {
		height: 100px;
		flex-direction: column;
	}

	a {
		outline: 0;
	}

	ul {
		display: flex;
		align-items: center;

		@media screen and (max-width: 700px) {
		}

		@media screen and (max-width: 600px) {
		}

		@media screen and (max-width: 300px) {
		}

		li {
			color: ${colors.secondaryColor};
			font-weight: 500;
			padding-right: 15px;

			&:last-child {
				padding-right: 8px;
			}

			&:focus {
				outline: none;
				background-color: crimson;
			}
		}
	}
`;

export default Navbar;

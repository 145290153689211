import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Redirect } from 'react-router-dom';

import logo from '../../img/fca_logo.png';
import FormInput from '../../components/forms/FormInput';
import FormButton from '../../components/forms/FormButton';
import TextLinkButton from '../../components/TextLinkButton';

import { signUpUser } from '../../store/ducks/auth';
import { colors } from '../../constants/colors';

export default function SignUpPage() {
	const dispatch = useDispatch();
	const auth = useSelector((state) => state.auth.isAuthenticated);

	const validationSchema = Yup.object({
		username: Yup.string().required('* all fields field must be filled'),
		email: Yup.string().required('* all fields field must be filled'),
		password: Yup.string().required('* all fields field must be filled'),
		confirmPassword: Yup.string()
			.required('* all fields must be filled')
			.oneOf([Yup.ref('password'), null], 'Passwords must match'),
	});

	const initialValues = {
		username: '',
		email: '',
		password: '',
		confirmPassword: '',
	};

	if (auth) return <Redirect to='/votingPage' />;

	return (
		<div className='auth_bg'>
			<InnerWrapper>
				<PageHeader>
					<BrandContainer>
						<Link to='/'>
							<BrandImage src={logo} alt='FCA Logo' />
						</Link>
						<BrandTextContainer>
							<BrandTextTop>Fan Choice</BrandTextTop>
							<BrandTextBottom>Awards</BrandTextBottom>
						</BrandTextContainer>
					</BrandContainer>
					<PageTitle>Welcome,</PageTitle>
					<PageTitle>register to get started</PageTitle>
				</PageHeader>

				<Formik
					initialValues={initialValues}
					validationSchema={validationSchema}
					onSubmit={(values) => {
						dispatch(signUpUser(values));
					}}
				>
					{({ values, handleChange, handleSubmit }) => (
						<PageContent onSubmit={handleSubmit}>
							<FormGroup>
								<FormInput
									name='username'
									placeholder='Enter your username'
									inputType='text'
									label='Username'
									value={values.username}
									onChange={(e) => handleChange(e)}
								/>
								<ErrorMessage name='username' component={ErrorText} />
							</FormGroup>
							<FormGroup>
								<FormInput
									name='email'
									placeholder='Enter your email address'
									inputType='email'
									label='Email'
									value={values.email}
									onChange={(e) => handleChange(e)}
								/>
								<ErrorMessage name='email' component={ErrorText} />
							</FormGroup>
							<FormGroup>
								<FormInput
									name='password'
									placeholder='Enter your password'
									inputType='password'
									label='Password'
									value={values.password}
									onChange={(e) => handleChange(e)}
								/>
								<ErrorMessage name='password' component={ErrorText} />
							</FormGroup>
							<FormGroup>
								<FormInput
									name='confirmPassword'
									placeholder='Confirm password'
									inputType='password'
									label='Confirm Password'
									value={values.confirmPassword}
									onChange={(e) => handleChange(e)}
								/>
								<ErrorMessage name='confirmPassword' component={ErrorText} />
							</FormGroup>
							<FormGroup>
								<FormButton buttonLabel='Submit' type='submit' />
							</FormGroup>
						</PageContent>
					)}
				</Formik>
			</InnerWrapper>
			<TextLinkButton to='/signin'>Already have an account? Login</TextLinkButton>
		</div>
	);
}

const InnerWrapper = styled.div`
	width: 60%;
	height: auto;
	padding: 15px;
	border-radius: 20px;
	background-color: ${colors.whiteColor};
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;

	@media screen and (max-width: 900px) {
		width: 60%;
	}
	@media screen and (max-width: 768px) {
		width: 60%;
	}
	@media screen and (max-width: 600px) {
		width: 80%;
	}
	@media screen and (max-width: 300px) {
		width: 90%;
	}
`;

const PageHeader = styled.div`
	width: 40%;
	margin-bottom: 10px;
	display: flex;
	flex-direction: column;
	align-items: center;

	@media screen and (max-width: 970px) {
		width: 50%;
	}
	@media screen and (max-width: 850px) {
		display: none;
	}
	@media screen and (max-width: 768px) {
		display: none;
	}
	@media screen and (max-width: 600px) {
		display: none;
	}
	@media screen and (max-width: 300px) {
		display: none;
	}
`;

const PageContent = styled.form`
	width: 60%;
	padding: 30px 50px;
	display: flex;
	align-self: center;
	flex-direction: column;

	@media screen and (max-width: 970px) {
		width: 70%;
	}

	@media screen and (max-width: 850px) {
		width: 80%;
	}

	@media screen and (max-width: 768px) {
		width: 80%;
	}

	@media screen and (max-width: 710px) {
		width: 100%;
	}

	@media screen and (max-width: 610px) {
		width: 100%;
	}
	@media screen and (max-width: 300px) {
		width: 100%;
	}
`;

const BrandContainer = styled.div`
	width: 100%;
	padding: 5px;
	margin: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
`;

const BrandImage = styled.img`
	width: 130px;
	height: auto;
	padding-left: 30px;
`;

const BrandTextContainer = styled.div`
	width: 50%;
	padding-left: 15px;
	display: flex;
	flex-direction: column;
`;

const BrandTextTop = styled.h2`
	font-size: 22px;
	font-weight: 300;
	font-family: 'Roboto', sans-serif;
	color: ${colors.textColor};
`;

const BrandTextBottom = styled.h2`
	font-size: 32px;
	font-weight: 400;
	font-family: 'Roboto', sans-serif;
	color: ${colors.textColor};
`;

const PageTitle = styled.h2`
	font-size: 18px;
	font-weight: 300;
	font-family: 'Roboto', sans-serif;
	padding: 5px 0 0;
	color: ${colors.textColor};
`;

const FormGroup = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	margin: 10px 0;
`;

const ErrorText = styled.h2`
	font-size: 14px;
	font-weight: 400;
	text-align: center;
	color: ${colors.redColor};
`;

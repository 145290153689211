import React from 'react';
import styled from 'styled-components';
import Slide from 'react-reveal/Slide';

import Hero from '../components/Hero';
import SportsTypes from '../components/SportsTypes';
import AwardsProcess from '../components/AwardsProcess';
import Partnership from '../components/Partnership';
import Subscribe from '../components/Subscribe';
import News from '../components/News';

export default function LandingPage() {
	return (
		<Container>
			<Hero />
			<News />
			<Slide bottom>
				<Partnership />
			</Slide>
			<Slide bottom>
				<AwardsProcess />
			</Slide>
			<Subscribe />
			<Slide bottom>
				<SportsTypes />
			</Slide>
		</Container>
	);
}

const Container = styled.div`
	width: 100%;
	margin: 0 auto;

	@media screen and (max-width: 768px) {
		width: 90%;
	}

	@media screen and (max-width: 600px) {
		width: 90%;
	}

	@media screen and (max-width: 300px) {
		width: 90%;
	}
`;

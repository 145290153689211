import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';

import { colors } from '../../constants/colors';
import { signOut } from '../../store/ducks/auth';

export default function SignedInLinks() {
	const dispatch = useDispatch();

	return (
		<ul className='right'>
			<li>
				<NavLink to='/' activeStyle={{ color: colors.textColor }}>
					Home
				</NavLink>
			</li>
			<li>
				<NavLink to='/about' activeStyle={{ color: colors.textColor }}>
					About
				</NavLink>
			</li>
			<li>
				<NavLink to='/nominees' activeStyle={{ color: colors.textColor }}>
					Nominees
				</NavLink>
			</li>
			<li>
				<NavLink to='/votingPage' activeStyle={{ color: colors.textColor }}>
					MyVotes
				</NavLink>
			</li>
			<li>
				<NavLink to='/awards' activeStyle={{ color: colors.textColor }}>
					Categories
				</NavLink>
			</li>
			<li>
				<NavLink to='/contact' activeStyle={{ color: colors.textColor }}>
					Contact
				</NavLink>
			</li>
			<li>
				<Button onClick={() => dispatch(signOut())}>Log Out</Button>
			</li>
		</ul>
	);
}

const Button = styled.button`
	background-color: ${colors.secondaryColor};
	color: ${colors.whiteColor};
	padding: 10px 15px;
	border-radius: 5px;

	&:hover {
		background-color: ${colors.primaryColor};
		color: ${colors.secondaryColor};
	}
`;

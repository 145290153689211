import React from 'react';
import styled from 'styled-components';
import { IoLogoFacebook, IoLogoTwitter, IoLogoInstagram } from 'react-icons/io';

import '../styles/index.css';
import { colors } from '../constants/colors';

export default function Hero() {
	return (
		<Container>
			<div className='hero-image'>
				<HeroContentWrapper>
					<HeroHeading>Hello fan, welcome to the home of the Fan Choice Awards.</HeroHeading>
					<MainText>
						The <b>Fan Choice Awards</b> gives you the fan the power to nominate and vote for sports players, coaches
						and teams that have entertained and impressed you during the past 12-months of sport.
					</MainText>

					<RegisterButton>Follow our Socials</RegisterButton>
					<HeroInnerContentContainer>
						<IconButton target='_blank' href='https://www.facebook.com/FanChoiceAwardsSA/'>
							<IoLogoFacebook />
						</IconButton>
						<IconButton target='_blank' href='https://twitter.com/fc_awardsza'>
							<IoLogoTwitter />
						</IconButton>
						<IconButton target='_blank' href='https://www.instagram.com/fanchoiceawardssa/'>
							<IoLogoInstagram />
						</IconButton>
					</HeroInnerContentContainer>
				</HeroContentWrapper>
			</div>
		</Container>
	);
}

const Container = styled.section`
	background-color: ${colors.primaryColor};
	width: 100%;
`;

const HeroContentWrapper = styled.div`
	max-width: 600px;
	margin: 0 auto;
	padding-top: 50px;
	text-align: center;

	@media screen and (max-width: 420px) {
		padding-top: 25px;
	}
`;

const HeroHeading = styled.h1`
	line-height: 1.4;
	font-size: 38px;
	font-weight: 700;
	color: ${colors.whiteColor};
	padding: 40px 0 50px;

	@media screen and (max-width: 1200px) {
		font-size: 32px;
		padding: 60px 100px 50px;
	}

	@media screen and (max-width: 992px) {
		font-size: 32px;
		padding: 60px 100px 50px;
	}

	@media screen and (max-width: 768px) {
		font-size: 32px;
		padding: 60px 100px 50px;
	}

	@media screen and (max-width: 420px) {
		font-size: 32px;
		padding: 30px 20px 50px;
	}

	@media only screen and (min-device-width: 320px) and (max-device-width: 568px) {
		font-size: 22px;
		padding: 20px 20px 30px;
	}
`;

const MainText = styled.p`
	color: ${colors.whiteColor};
	font-size: 16px;
	line-height: 1.4;
	font-weight: 400;
	padding: 0 75px;

	@media screen and (max-width: 420px) {
		font-size: 18px;
		padding: 0 30px;
	}
`;

const HeroInnerContentContainer = styled.div`
	width: 280px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;

	align-self: center;
	padding: 10px 15px;
	margin: 20px auto;
	border-radius: 5px;
`;

const IconButton = styled.a`
	color: #2d3748;
	font-size: 38px;
	padding: 5px;
	border-radius: 5px;
	background-color: ${colors.primaryColor};
	cursor: pointer;

	&:hover {
		background-color: ${colors.secondaryColor};
		color: ${colors.primaryColor};
	}
`;

const RegisterButton = styled.div`
	width: 50%;
	padding: 10px 30px;
	font-size: 18px;
	font-weight: 500;
	text-transform: uppercase;
	border-radius: 20px;
	margin: 30px auto;
	color: ${colors.whiteColor};
	background-color: ${colors.redColor};

	@media screen and (max-width: 1200px) {
	}

	@media screen and (max-width: 992px) {
	}

	@media screen and (max-width: 768px) {
		font-size: 14px;
	}

	@media screen and (max-width: 420px) {
		font-size: 14px;
	}

	@media only screen and (min-device-width: 320px) and (max-device-width: 568px) {
		font-size: 14px;
	}
`;

import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import styled from 'styled-components';

import { colors } from '../constants/colors';

const TextLinkButton = (props) => {
	const { history, location, match, staticContext, to, onClick, ...rest } = props;
	return (
		<CustomButton
			{...rest}
			onClick={(event) => {
				onClick && onClick(event);
				history.push(to);
			}}
		/>
	);
};

TextLinkButton.propTypes = {
	to: PropTypes.string.isRequired,
	children: PropTypes.node.isRequired,
};

const CustomButton = styled.button`
	color: ${colors.whiteColor};
	margin-top: 20px;
`;

export default withRouter(TextLinkButton);

import React from 'react';
import { IoMdArrowForward } from 'react-icons/io';
import styled from 'styled-components';

import { colors } from '../constants/colors';

export default function AwardsProcess() {
	const renderArrow = (phase) => {
		if (phase.title === 'Winners Announced') {
			return null;
		} else {
			return (
				<PointerArrow>
					<IoMdArrowForward />
				</PointerArrow>
			);
		}
	};

	return (
		<SectionContainer>
			<AwardHeading>Awards Process</AwardHeading>
			<DescriptionText>6-weeks of action packed awards marathon</DescriptionText>
			<AwardsContainer>
				{phases.map((phase) => (
					<AwardWrapper key={phase.id}>
						<AwardInnerWrapper>
							<AwardGoldContainer>
								<AwardText>{phase.title}</AwardText>
								<AwardImage src={phase.icon} key={phase.id} alt={phase.title} />
								<AwardPeriod>
									<b>Period:</b> {phase.period}
								</AwardPeriod>
							</AwardGoldContainer>
							<AwardBottomBox>
								<AwardPeriodDescription>{phase.text}</AwardPeriodDescription>
							</AwardBottomBox>
						</AwardInnerWrapper>
						{renderArrow(phase)}
					</AwardWrapper>
				))}
			</AwardsContainer>
		</SectionContainer>
	);
}

const phases = [
	{
		id: 0,
		icon: require('../img/processIcons/checkbox.svg'),
		title: 'Nominations Commence',
		period: '3 weeks',
		text: 'Fans nominate their favourite candidates',
	},
	{
		id: 1,
		icon: require('../img/processIcons/publish.svg'),
		title: 'Finalists published',
		period: '1 week',
		text: 'FCA publishes nominated candidates',
	},
	{
		id: 2,
		icon: require('../img/processIcons/vote.svg'),
		title: 'Voting Commences',
		period: 'every 3 days',
		text: 'Fans vote for their favourite finalists',
	},
	{
		id: 3,
		icon: require('../img/processIcons/award.svg'),
		title: 'Winners Announced',
		period: 'end of day 3',
		text: 'Award winners announced at the end of day 3',
	},
];

const SectionContainer = styled.section`
	height: 100vh;
	background-color: #0f2027;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	@media screen and (max-width: 900px) {
		height: 80vh;
	}

	@media screen and (max-width: 768px) {
		height: 80vh;
	}

	@media screen and (max-width: 600px) {
		height: 80vh;
	}

	@media screen and (max-width: 300px) {
		height: 80vh;
	}
`;

const PointerArrow = styled.h2`
	color: white;
	font-size: 35px;
	border-radius: 5px;
	margin-bottom: 80px;

	@media screen and (max-width: 900px) {
		margin-bottom: 0;
	}

	@media screen and (max-width: 768px) {
		margin-bottom: 0;
	}

	@media screen and (max-width: 600px) {
		margin-bottom: 0;
		display: none;
	}

	@media screen and (max-width: 300px) {
		margin-bottom: 0;
		display: none;
	}
`;

const AwardHeading = styled.h1`
	font-size: 42px;
	font-weight: 700;
	color: ${colors.whiteColor};
`;

const DescriptionText = styled.h2`
	padding: 20px;
	color: white;
	text-align: center;
`;

const AwardsContainer = styled.div`
	height: 80vh;
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;

	@media screen and (max-width: 900px) {
		height: 60vh;
	}

	@media screen and (max-width: 768px) {
		height: 60vh;
		flex-direction: row;
	}

	@media screen and (max-width: 600px) {
		width: 100%;
		height: 60vh;
		flex-direction: row;
		flex-wrap: wrap;
	}

	@media screen and (max-width: 300px) {
		width: 100%;
		height: 60vh;
		flex-direction: row;
		flex-wrap: wrap;
	}
`;

const AwardWrapper = styled.div`
	width: 100%;
	display: flex;
	align-items: center;

	@media screen and (max-width: 900px) {
		width: 50%;
	}

	@media screen and (max-width: 768px) {
		width: 50%;
	}

	@media screen and (max-width: 600px) {
		width: 50%;
	}

	@media screen and (max-width: 300px) {
		width: 50%;
	}
`;

const AwardInnerWrapper = styled.div`
	margin: 0 auto;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;

	@media screen and (max-width: 900px) {
		flex-direction: column;
	}

	@media screen and (max-width: 768px) {
		flex-direction: column;
	}

	@media screen and (max-width: 600px) {
		flex-direction: column;
	}

	@media screen and (max-width: 300px) {
		flex-direction: column;
	}
`;

const AwardGoldContainer = styled.div`
	direction: column;
	background-color: ${colors.primaryColor};
	border-radius: 15px;
	width: 100%;
	height: 240px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	@media screen and (max-width: 900px) {
		width: 120px;
		height: 160px;
	}

	@media screen and (max-width: 768px) {
		width: 120px;
		height: 160px;
	}

	@media screen and (max-width: 600px) {
		width: 120px;
		height: 160px;
	}

	@media screen and (max-width: 300px) {
		width: 120px;
		height: 160px;
	}
`;

const AwardText = styled.h2`
	font-size: 16px;
	font-weight: 600;
	color: ${colors.secondaryColor};
	text-align: center;
	padding: 10px 0;
`;

const AwardImage = styled.img`
	height: 120px;
	width: auto;

	@media screen and (max-width: 900px) {
		height: 80px;
	}

	@media screen and (max-width: 768px) {
		height: 80px;
	}

	@media screen and (max-width: 600px) {
		height: 80px;
	}

	@media screen and (max-width: 300px) {
		height: 80px;
	}
`;

const AwardPeriod = styled.h2`
	font-size: 14px;
	font-weight: 600;
	color: ${colors.secondaryColor};
	text-align: center;

	@media screen and (max-width: 900px) {
		display: none;
	}

	@media screen and (max-width: 768px) {
		display: none;
	}

	@media screen and (max-width: 600px) {
		display: none;
	}

	@media screen and (max-width: 300px) {
		display: none;
	}
`;

const AwardBottomBox = styled.div`
	width: 200px;
	text-align: center;

	@media screen and (max-width: 900px) {
		display: none;
	}

	@media screen and (max-width: 768px) {
		display: none;
	}

	@media screen and (max-width: 600px) {
		display: none;
	}

	@media screen and (max-width: 300px) {
		display: none;
	}
`;

const AwardPeriodDescription = styled.h2`
	color: white;
	font-size: 16px;
	line-height: 1.3;
	padding: 20px 0;
	margin-top: 20px;
`;

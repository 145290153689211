import React from 'react';
import styled from 'styled-components';

import { colors } from '../../constants/colors';

const AwardsPage = () => {
	return (
		<Container>
			<Heading>Categories</Heading>
			<PageDescriptionText>
				The Fan Choice Awards 2020, focus on 3 sporting codes. Fans will be able to nominate and vote on a total of 9
				categories, all of whom either include male and female awards or are unisex:
			</PageDescriptionText>
			<AwardContainer>
				{awards.map((award) => (
					<AwardCard key={award.id}>
						<AwardCardSectionLeft style={{ flex: 0.3, backgroundColor: colors.primaryColor }}>
							<AwardNameText>{award.awardName}</AwardNameText>
						</AwardCardSectionLeft>
						<AwardCardSectionMiddle style={{ flex: 0.2, backgroundColor: colors.lightGreyColor }}>
							<GeneralText>{award.categoryShort}</GeneralText>
						</AwardCardSectionMiddle>
						<AwardCardSectionMiddle style={{ flex: 0.2, backgroundColor: colors.textColor }}>
							<GeneralText style={{ color: colors.whiteColor }}>{award.genderNote}</GeneralText>
						</AwardCardSectionMiddle>
						<AwardCardSectionRight
							style={{
								flex: 1,
								backgroundColor: colors.secondaryColor,
							}}
						>
							<DescriptionText>{award.description}</DescriptionText>
						</AwardCardSectionRight>
					</AwardCard>
				))}
			</AwardContainer>
		</Container>
	);
};

export default AwardsPage;

const Container = styled.div`
	width: 100%;
	padding: 30px 0;
	background-color: #f7fafc;
`;

const Heading = styled.h1`
	font-size: 34px;
	font-family: 'Montserrat', sans-serif;
	font-weight: 600;
	padding: 20px 0 0 40px;
	text-transform: capitalize;
	color: ${colors.secondaryColor};
`;

const PageDescriptionText = styled.h2`
	font-family: 'Montserrat', sans-serif;
	font-size: 16px;
	line-height: 1.2;
	margin-left: 40px;
	padding: 40px 100px 40px 0;
`;

const AwardCard = styled.div`
	width: 94%;
	height: 120px;
	margin: 40px auto;
	border-radius: 5px;
	background-color: ${colors.whiteColor};

	display: flex;
	flex-direction: row;
	justify-content: space-between;

	@media screen and (max-width: 1200px) {
		width: 90%;
		height: auto;
		flex-direction: column;
		border-radius: 0;
	}

	@media screen and (max-width: 600px) {
		width: 90%;
		height: auto;
		flex-direction: column;
		border-radius: 0;
	}

	@media screen and (max-width: 320px) {
		width: 90%;
		height: auto;
		flex-direction: column;
		border-radius: 0;
	}
`;

const AwardContainer = styled.div`
	height: auto;
	width: 100%;
`;

const AwardCardSectionLeft = styled.div`
	height: auto;
	padding: 15px;
	border-top-left-radius: 5px;
	border-bottom-left-radius: 5px;

	@media screen and (max-width: 1200px) {
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
	}

	@media screen and (max-width: 600px) {
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
	}

	@media screen and (max-width: 320px) {
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
	}
`;

const AwardCardSectionMiddle = styled.div`
	height: auto;
	padding: 15px;
`;

const AwardCardSectionRight = styled.div`
	height: auto;
	padding: 15px;
	border-top-right-radius: 5px;
	border-bottom-right-radius: 5px;

	@media screen and (max-width: 1200px) {
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
	}

	@media screen and (max-width: 600px) {
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
	}

	@media screen and (max-width: 320px) {
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
	}
`;

const AwardNameText = styled.h2`
	font-size: 22px;
	font-family: 'Montserrat', sans-serif;
	color: ${colors.secondaryColor};
`;

const GeneralText = styled.h2`
	font-size: 22px;
	font-family: 'Montserrat', sans-serif;
	color: ${colors.secondaryColor};
`;

const DescriptionText = styled.h2`
	font-size: 16px;
	font-family: 'Montserrat', sans-serif;
	line-height: 1.4;
	color: ${colors.whiteColor};
`;

const awards = [
	{
		id: 0,
		awardName: 'Fan of the Year',
		categoryShort: 'FOTY',
		genderNote: 'Unisex',
		description:
			"This award is for the fan's fan. The person that inspired us with their creativity, spirit and commitment. They are the perfect ambassador for role of the 12th man.",
	},
	{
		id: 1,
		awardName: 'Player of the Year',
		categoryShort: 'POTY',
		genderNote: 'Male and Female',
		description:
			'A player that has been outstanding in their performances during the season. They have entertained and made a significance difference for their team on the pitch. Most of all, they have impressed fans.',
	},
	{
		id: 2,
		awardName: 'Coach of the Year',
		categoryShort: 'COTY',
		genderNote: 'Unisex',
		description:
			"This mentor was the best at managing their team, outwitting opponents and producing results. They have shown themselves to be masterful at reading the game, using their tactical acumen to turn their team's unfair advantage into desired results.",
	},
	{
		id: 3,
		awardName: 'Team of the Year',
		categoryShort: 'TOTY',
		genderNote: 'Male and Female',
		description:
			'The team that impressed both fans and foes during a full season of domestic sport. They always seem to do the right things at the perfect time, including grinding out key results. They have overcome numerous challenges and stood head and shoulders with the best.',
	},
	{
		id: 4,
		awardName: 'Young Player of the Year',
		categoryShort: 'YPOTY',
		genderNote: 'Male and Female',
		description:
			"Matured well beyond their age, this player showed opponents flames during the past season. They emerged as one of the best performers in their position across the league (or competition) and have become a permanent fixture in their team's starting eleven. ",
	},
	{
		id: 5,
		awardName: 'Administrator of the Year',
		categoryShort: 'AOTY',
		genderNote: 'Unisex',
		description:
			"Behind every successful team is an army of staffers who take care of all the unglamorous behind the scenes work that make a significant impact on the points on the table and the smiles of the fan's face. This award is for the unsung heroes.",
	},
	{
		id: 6,
		awardName: "Supporters' Branch of the Year",
		categoryShort: 'SBOTY',
		genderNote: 'Unisex',
		description:
			'Supporters Branches are the rightful homes of fans. It is where fans congregate to socialise and share common interests. They the perfect environment for chanting and praise singing during happy times. Places where grown folk can cry and hug during unpleasant times. They give all of us a place to belong.',
	},
	{
		id: 7,
		awardName: 'National Team of the Year',
		categoryShort: 'NTOTY',
		genderNote: 'Unisex',
		description:
			'Every year, our national teams go out in the world with intentions to overcome the odds, excel and make us proud, but not all of them succeed in their quest. This award recognises the team that impressed us all.',
	},
	{
		id: 8,
		awardName: 'Rewind Award',
		categoryShort: 'RWA',
		genderNote: 'Unisex',
		description:
			'This award celebrates players for their past performance during a specific year. It asks fans to relive past moments and reward sports legends after the fact.',
	},
];

export const colors = {
	primaryColor: '#f6e05e',
	secondaryColor: '#2d3748',
	lightGreyColor: '#ebedf0',
	textColor: '#859398',
	blackColor: '#0f2027',
	whiteColor: '#fff',
	redColor: '#c53030',
	greenColor: '#007A4D',
};

import firebase from '../../config/firebase';

const db = firebase.firestore();
const fbAuth = firebase.auth();

// Types
const AUTH_USER = 'AUTH_USER';
const SIGN_UP_USER = 'SIGN_UP_USER';
const SIGN_IN_USER = 'SIGN_IN_USER';
const SIGN_OUT_USER = 'SIGN_OUT_USER';
const SIGN_UP_USER_ERROR = 'SIGN_UP_USER_ERROR';
const SIGN_IN_USER_ERROR = 'SIGN_IN_USER_ERROR';

// Actions
export const getUser = (uid) => {
	return async (dispatch) => {
		try {
			const user = await db.collection('users').doc(uid).get();
			dispatch({ type: AUTH_USER, payload: user.data() });
		} catch (err) {
			alert(err.message);
		}
	};
};

export const signUpUser = (userData = {}) => {
	return async (dispatch) => {
		try {
			const { username, email, password } = userData;
			await fbAuth.createUserWithEmailAndPassword(email, password).then((res) => {
				const user = {
					id: res.user.uid,
					username,
					email,
					nominations: [],
					votes: [],
				};

				db.collection('users').doc(res.user.uid).set(user);
				dispatch({ type: SIGN_UP_USER, payload: user });
			});
		} catch (err) {
			let message;

			if (err.message === 'auth/email-already-exists') {
				message = 'Eish, askies that email address is already taken';
			}
			dispatch({ type: SIGN_UP_USER_ERROR, payload: message });
		}
	};
};

export const signInUser = (credentials) => {
	return async (dispatch) => {
		try {
			await fbAuth.signInWithEmailAndPassword(credentials.email, credentials.password).then((res) => {
				dispatch({ type: SIGN_IN_USER, payload: res.user.uid });
			});
		} catch (err) {
			let message;

			if (err.message === 'auth/invalid-email' || err.message === 'auth/invalid-password') {
				message = 'Eish, askies your login details are invalid. Please try again.';
			} else if (
				err.message === 'There is no user record corresponding to this identifier. The user may have been deleted.'
			) {
				message = 'Eish, askies that account does not exist. Please try different login details';
			} else if (err.message === 'auth/user-not-found') {
				message = 'Eish, askies that account does not exist. Please try different login details';
			}
			dispatch({ type: SIGN_IN_USER_ERROR, payload: message });
		}
	};
};

export const signOut = () => {
	return (dispatch) => {
		fbAuth.signOut().then(() => {
			dispatch({ type: SIGN_OUT_USER });
		});
	};
};

export function authUser() {
	return {
		type: AUTH_USER,
	};
}

export const verifyAuth = () => {
	return (dispatch) => {
		try {
			fbAuth.onAuthStateChanged((user) => {
				if (user) {
					dispatch(authUser());
				} else {
					dispatch(signOut());
				}
			});
		} catch (err) {
			alert(err.message);
		}
	};
};

// Reducer
const INITIAL_STATE = {
	authError: null,
	isAuthenticated: false,
	user: '',
};

const authReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case SIGN_OUT_USER:
			return {
				isAuthenticated: false,
			};
		case SIGN_UP_USER:
			return {
				...state,
				isAuthenticated: true,
			};
		case SIGN_UP_USER_ERROR:
			return {
				...state,
				authError: action.payload,
			};
		case SIGN_IN_USER:
			return {
				...state,
				user: action.payload,
				isAuthenticated: true,
			};
		case SIGN_IN_USER_ERROR:
			return {
				...state,
				authError: action.payload,
			};

		default:
			return state;
	}
};

export { authReducer };

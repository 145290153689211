import React from 'react';
import styled from 'styled-components';

import { colors } from '../../constants/colors';

export default function FormInput({ inputType, placeholder, name, labelHtml, label, value, onChange, inputMarginTop }) {
	return (
		<InputContainer style={{ marginTop: inputMarginTop }}>
			<InputLabel htmlFor={labelHtml}>{label}</InputLabel>
			<Input type={inputType} placeholder={placeholder} name={name} value={value} onChange={onChange} />
		</InputContainer>
	);
}

const InputContainer = styled.div`
	width: 100%;
	margin-bottom: 5px;
	display: flex;
	flex-direction: column;
`;

const Input = styled.input`
	width: 100%;
	height: 37px;
	padding: 0px 10px;
	font-size: 16px;
	font-weight: 300;
	font-family: 'Montserrat', sans-serif;
	background-color: #f3f3f3;
	border-radius: 4px;
	border: 0;
	transition: all 250ms ease-in-out;

	&:focus {
		outline: none;
		box-shadow: 0px 0px 3px 0.8px ${colors.primaryColor};
	}
`;

const InputLabel = styled.label`
	font-size: 16px;
	font-family: 'Montserrat', sans-serif;
	padding-bottom: 5px;
	color: ${colors.textColor};
`;

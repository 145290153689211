import React, { useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

import SuccessMessage from './SuccessMessage';
import LimitErrorMessage from './LimitErrorMessage';
import firebase from '../../config/firebase';

const VotingForm = ({ history }) => {
	const [name, setName] = useState('');
	const [limitError, setLimitError] = useState('');
	const [isSubmitted, setIsSubmitted] = useState(false);
	const auth = useSelector((state) => state.auth.user);
	const isDisabled = name === '';

	const formSubmit = async (event) => {
		event.preventDefault();

		await firebase
			.firestore()
			.collection('users')
			.where('id', '==', auth)
			.get()
			.then((snapshot) => {
				snapshot.forEach((doc) => {
					if (doc.exists) {
						const vote = {
							nominee: name,
							votes: firebase.firestore.FieldValue.increment(1),
							createdAt: firebase.firestore.Timestamp.now(),
						};

						firebase
							.firestore()
							.collection('players')
							.doc(name)
							.update(vote)
							.then(() => {
								setName({
									name,
								});
								setIsSubmitted({ isSubmitted: true });

								setTimeout(() => history.push('/votingPage'), 1500);
							})
							.catch((err) => {
								console.log(err);
								setLimitError({ limitError: err });
								setTimeout(() => history.push('/votingPage'), 2000);
							});
					}
				});
			});
	};

	if (!auth) return <Redirect to='/signin' />;

	return (
		<Container>
			{isSubmitted ? (
				<SuccessMessage />
			) : limitError ? (
				<LimitErrorMessage />
			) : (
				<InnerWrapper>
					<FormHeading>Player of the Year Nominees</FormHeading>

					<form onSubmit={formSubmit}>
						<FormGroup>
							<ButtonsGroup>
								<div className='radio' style={{ marginBottom: 30 }}>
									<input
										type='radio'
										value='Samir Nurković'
										checked={name === 'Samir Nurković'}
										onChange={(e) => {
											setName(e.target.value);
										}}
									/>
									<NomineeLabel>Samir Nurković</NomineeLabel>
								</div>

								<div className='radio' style={{ marginBottom: 30 }}>
									<input
										type='radio'
										value='Themba Zwane'
										checked={name === 'Themba Zwane'}
										onChange={(e) => {
											setName(e.target.value);
										}}
									/>
									<NomineeLabel>Themba Zwane</NomineeLabel>
								</div>

								<div className='radio' style={{ marginBottom: 30 }}>
									<input
										type='radio'
										value='Siya Kolisi'
										checked={name === 'Siya Kolisi'}
										onChange={(e) => {
											setName(e.target.value);
										}}
									/>
									<NomineeLabel>Siya Kolisi</NomineeLabel>
								</div>

								<div className='radio' style={{ marginBottom: 30 }}>
									<input
										type='radio'
										value='Rhoda Mulaudzi'
										checked={name === 'Rhoda Mulaudzi'}
										onChange={(e) => {
											setName(e.target.value);
										}}
									/>
									<NomineeLabel>Rhoda Mulaudzi</NomineeLabel>
								</div>

								<div className='radio' style={{ marginBottom: 30 }}>
									<input
										type='radio'
										value='Ndumiso Mabena'
										checked={name === 'Ndumiso Mabena'}
										onChange={(e) => {
											setName(e.target.value);
										}}
									/>
									<NomineeLabel>Ndumiso Mabena</NomineeLabel>
								</div>

								<div className='radio' style={{ marginBottom: 30 }}>
									<input
										type='radio'
										value='Lukhanyo Am'
										checked={name === 'Lukhanyo Am'}
										onChange={(e) => {
											setName(e.target.value);
										}}
									/>
									<NomineeLabel>Lukhanyo Am</NomineeLabel>
								</div>

								<div className='radio' style={{ marginBottom: 30 }}>
									<input
										type='radio'
										value='Ben Motswari'
										checked={name === 'Ben Motswari'}
										onChange={(e) => {
											setName(e.target.value);
										}}
									/>
									<NomineeLabel>Ben Motswari</NomineeLabel>
								</div>

								<div className='radio' style={{ marginBottom: 30 }}>
									<input
										type='radio'
										value='Motebang Sera'
										checked={name === 'Motebang Sera'}
										onChange={(e) => {
											setName(e.target.value);
										}}
									/>
									<NomineeLabel>Motebang Sera</NomineeLabel>
								</div>
							</ButtonsGroup>

							<SubmitButton disabled={isDisabled} type='submit'>
								Submit
							</SubmitButton>
						</FormGroup>
					</form>
				</InnerWrapper>
			)}
		</Container>
	);
};

const Container = styled.div`
	height: 700px;
	margin: 40px auto;
	min-height: 400px;
	display: flex;
	justify-content: center;
`;

const InnerWrapper = styled.div`
	display: flex;
	align-items: center;
	flex-direction: column;
`;

const ButtonsGroup = styled.div`
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	padding: 50px 250px 10px;

	@media screen and (max-width: 768px) {
		padding: 50px 100px 10px;
	}
	@media screen and (max-width: 600px) {
		padding: 50px 100px 10px;
	}
	@media screen and (max-width: 300px) {
		padding: 50px 100px 10px;
	}
`;

const FormGroup = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`;

const FormHeading = styled.h2`
	margin: 30px 0;
	font-size: 32px;
	font-weight: 600;
`;

const NomineeLabel = styled.label`
	margin-left: 30px;
	font-size: 18px;
	font-weight: 600;
`;

const SubmitButton = styled.button`
	color: #2d3748;
	background-color: #f6e05e;
	padding: 8px 70px;
	border-radius: 5px;
	font-weight: 600;
	margin-top: 20px;
	outline: none;
`;

export default VotingForm;

import React from 'react';
import styled from 'styled-components';

import { colors } from '../../constants/colors';

export default function FormButton({ buttonLabel, onClick, type }) {
	return (
		<Button type={type} onClick={onClick}>
			{buttonLabel}
		</Button>
	);
}

const Button = styled.button`
	width: 100%;
	margin-top: 5px;
	padding: 10px 30px;
	border-radius: 5px;
	font-family: 'Montserrat', sans-serif;
	border: 0;
	background-color: ${colors.primaryColor};
	transition: all 250ms ease-in-out;
	cursor: pointer;

	&:hover {
		background-color: ${colors.secondaryColor};
		color: ${colors.primaryColor};
	}

	&:focus {
		outline: none;
	}
`;

import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Redirect } from 'react-router-dom';

import logo from '../../img/fca_logo.png';
import FormInput from '../../components/forms/FormInput';
import FormButton from '../../components/forms/FormButton';
import { signInUser } from '../../store/ducks/auth';
import TextLinkButton from '../../components/TextLinkButton';

import { colors } from '../../constants/colors';

export default function SignInPage() {
	const dispatch = useDispatch();
	const authError = useSelector((state) => state.auth.authError);
	const auth = useSelector((state) => state.auth.user);

	const validationSchema = Yup.object({
		email: Yup.string().required('* all fields field must be filled'),
		password: Yup.string().required('* all fields field must be filled'),
	});

	const initialValues = {
		email: '',
		password: '',
	};

	if (auth) return <Redirect to='/votingPage' />;

	return (
		<div className='auth_bg'>
			<InnerWrapper>
				<PageHeader>
					<BrandContainer>
						<Link to='/'>
							<BrandImage src={logo} alt='FCA Logo' />
						</Link>
						<BrandTextContainer>
							<BrandTextTop>Fan Choice</BrandTextTop>
							<BrandTextBottom>Awards</BrandTextBottom>
						</BrandTextContainer>
					</BrandContainer>
					<PageTitle>Welcome back,</PageTitle>
					<PageTitle>please login to get started</PageTitle>
					<ErrorText>{authError}</ErrorText>
				</PageHeader>
				<Formik
					initialValues={initialValues}
					validationSchema={validationSchema}
					onSubmit={(values) => {
						dispatch(signInUser(values));
					}}
				>
					{({ values, handleChange, handleSubmit }) => (
						<PageContent onSubmit={handleSubmit}>
							<FormGroup style={{ marginTop: 30 }}>
								<FormInput
									name='email'
									placeholder='Enter your email address'
									inputType='email'
									label='Email'
									value={values.email}
									onChange={(e) => handleChange(e)}
								/>
								<ErrorMessage name='email' component={ErrorText} />
							</FormGroup>
							<FormGroup style={{ marginBottom: 20 }}>
								<FormInput
									name='password'
									placeholder='Enter your password'
									inputType='password'
									label='Password'
									value={values.password}
									onChange={(e) => handleChange(e)}
								/>
								<ErrorMessage name='password' component={ErrorText} />
							</FormGroup>
							<FormButton buttonLabel='Submit' type='submit' />
						</PageContent>
					)}
				</Formik>
			</InnerWrapper>
			<TextLinkButton to='/register'>Don't have an account? Register</TextLinkButton>
		</div>
	);
}

const InnerWrapper = styled.div`
	width: 360px;
	height: 80%;
	padding: 10px;
	border-radius: 20px;
	background-color: ${colors.whiteColor};
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;

const PageHeader = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	flex-direction: center;
`;

const BrandContainer = styled.div`
	width: 100%;
	padding: 5px;
	margin: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
`;

const PageContent = styled.form`
	width: 80%;
	display: flex;
	align-self: center;
	flex-direction: column;
`;

const BrandImage = styled.img`
	width: 130px;
	height: auto;
	padding-left: 30px;
`;

const BrandTextContainer = styled.div`
	width: 50%;
	padding-left: 15px;
	display: flex;
	flex-direction: column;
`;

const BrandTextTop = styled.h2`
	font-size: 22px;
	font-weight: 300;
	font-family: 'Roboto', sans-serif;
	color: ${colors.textColor};
`;

const BrandTextBottom = styled.h2`
	font-size: 32px;
	font-weight: 400;
	font-family: 'Roboto', sans-serif;
	color: ${colors.textColor};
`;

const PageTitle = styled.h2`
	font-size: 18px;
	font-weight: 300;
	font-family: 'Roboto', sans-serif;
	padding: 5px 0;
	color: ${colors.textColor};
`;

const FormGroup = styled.div`
	width: 100%;
	margin: 10px 0;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
`;

const ErrorText = styled.h2`
	font-size: 16px;
	font-weight: 400;
	margin: 5px;
	width: 70%;
	text-align: center;
	color: ${colors.redColor};
`;

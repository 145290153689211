import { createStore, applyMiddleware, combineReducers, compose } from 'redux';
import thunk from 'redux-thunk';
import { persistStore, persistReducer, createMigrate } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import hardSet from 'redux-persist/lib/stateReconciler/hardSet';

import { authReducer } from './ducks/auth';
import { nominationsReducer } from './ducks/nominations';
import { votingReducer } from './ducks/voting';

import { contactReducer } from './ducks/contact';
import { verifyAuth } from './ducks/auth';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const rootReducer = combineReducers({
	auth: authReducer,
	nominations: nominationsReducer,
	voting: votingReducer,
	contact: contactReducer,
});

const migrations = {
	0: (state) => {
		// migration clear out device state
		return {
			...state,
			device: undefined,
		};
	},
	1: (state) => {
		// migration to keep only device state
		return {
			device: state.device,
		};
	},
};

const persistConfig = {
	key: 'root',
	storage,
	whitelist: ['auth', 'nominations', 'voting'],
	blacklist: ['contact'],
	migrate: createMigrate(migrations, { debug: false }),
	stateReconciler: hardSet,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(persistedReducer, composeEnhancers(applyMiddleware(thunk)));

const persistor = persistStore(store);

store.dispatch(verifyAuth());

export { store, persistor };
